import { Component, inject, Input, OnInit } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdditionalCoverageBoxComponent } from '@shared/components/additional-coverage-box/additional-coverage-box.component';
import { SkipperVersicherungssummeKundeEnum } from '@shared/models/skipper-versicherungssumme-kunde.enum';
import { TranslocoPipe } from '@ngneat/transloco';
import { QuestionBoxComponent } from '@shared/components/question-box/question-box.component';
import { CalculationErrorDisplayComponent } from '@shared/components/calculation-error-display/calculation-error-display.component';
import { PremiumDisplayComponent } from '@shared/components/premium-display/premium-display.component';
import { NgClass } from '@angular/common';
import { takeUntil } from 'rxjs';
import { countryAnswerOptions } from '@shared/models/country-registered-answer-options';
import { AdditionalCoverageBoxForEnumComponent } from '@shared/components/additional-coverage-for-enum/additional-coverage-box-for-enum.component';
import { SkipperHaftpflichtComponent } from '../skipper-haftpflicht/skipper-haftpflicht.component';
import { SkipperRechtsschutzComponent } from '../skipper-rechtsschutz/skipper-rechtsschutz.component';
import { DataBaseComponent } from '@shared/directives/data-base.component';
import { SkipperFormModel } from '@shared/models/forms/skipper-form.model';
import { SkipperCalculationService } from '../../services/skipper-calculation.service';

@Component({
  selector: 'app-skipper-general',
  standalone: true,
  imports: [
    FormsModule,
    AdditionalCoverageBoxComponent,
    ReactiveFormsModule,
    TranslocoPipe,
    QuestionBoxComponent,
    CalculationErrorDisplayComponent,
    PremiumDisplayComponent,
    NgClass,
    AdditionalCoverageBoxForEnumComponent,
    SkipperHaftpflichtComponent,
    SkipperRechtsschutzComponent
  ],
  templateUrl: './skipper-general.component.html',
  styleUrl: './skipper-general.component.scss'
})
export class SkipperGeneralComponent extends DataBaseComponent implements OnInit {

  skipperCalculationService = inject(SkipperCalculationService);

  @Input() form!: FormGroup<SkipperFormModel>;

  protected readonly countryAnswerOptions = countryAnswerOptions;

  ngOnInit(): void {
    this.calculate();
    this.form.valueChanges.pipe(takeUntil(this.componentDestroyed$)).subscribe(() => this.calculate());
    this.form.controls.hasHaftpflicht.valueChanges.pipe(takeUntil(this.componentDestroyed$)).subscribe((value) => {
      if (value) {
        this.form.controls.skipperHaftpflicht.controls.skipperVersicherungssummeKunde.setValue(SkipperVersicherungssummeKundeEnum.VS5MIO);
      }
    });
  }

  calculate(): void {
    this.skipperCalculationService
      .calculate(this.form);
  }

  canShowCalculationsResult(): boolean {
    return this.skipperCalculationService.calculationResult !== null && (!!this.form.controls.hasRechtsschutz.value || !!this.form.controls.hasHaftpflicht.value);
  }

}
