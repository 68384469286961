import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment/environment';
import { Observable } from 'rxjs';
import { CalculationResultModel } from '@shared/models/calculation-result.model';
import { SelbstbehaltKundeEnum } from '@shared/models/selbstbehalt-kunde.enum';
import { GetBvsVersicherungsPraemieCommandModel } from '@shared/models/commands/get-versicherungs-praemie-charter-kautionsversicherung-command.model';

@Injectable()
export class BvsHaftpflichtKaskoApiService {

  http = inject(HttpClient);

  get endpoint(): string {
    return environment.basePath + '/api/bvs/public';
  }

  calculate(getBvsVersicherungsPraemieCommand: GetBvsVersicherungsPraemieCommandModel): Observable<CalculationResultModel> {
    return this.http.post<CalculationResultModel>(`${this.endpoint}/haftplicht-und-kasko/versicherungs-praemie`, getBvsVersicherungsPraemieCommand);
  }

  getMoeglicheSelbstbehalte(command: GetBvsVersicherungsPraemieCommandModel): Observable<SelbstbehaltKundeEnum[]> {
    return this.http.post<SelbstbehaltKundeEnum[]>(`${this.endpoint}/kasko/moegliche-selbstbehalte`, command);
  }

}
