import { Component, Input } from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';
import { CallidusCurrencyPipe } from '@shared/pipes/currency/callidus-currency.pipe';

@Component({
  selector: 'app-premium-display',
  standalone: true,
  imports: [TranslocoPipe, CallidusCurrencyPipe],
  providers: [CallidusCurrencyPipe],
  templateUrl: './premium-display.component.html',
  styleUrl: './premium-display.component.scss'
})
export class PremiumDisplayComponent {
  @Input() premiumValue!: number;
  @Input() additionalHint: string | null = null;
  @Input() isFixedRate = false;

}
