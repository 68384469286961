<div class="question">
  <div class="question-title">
    {{ title | transloco }}
  </div>

  <div class="answer-boxes row">
    @for (answer of answers;track $index) {
      <div
        (click)="setSelectedAnswer(answer)"
        [ngClass]="{ 'selected': selectedAnswer?.id === answer.id, 'is-disabled': answer.isDisabled}"
        [attr.data-cy]="id+'-'+answer.id"
        class="answer-box col-sm col-md-auto">
        {{ answer.title | transloco }}
      </div>
    }
  </div>
</div>
