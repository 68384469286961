<section class="container-fluid position-relative">
  <div class="container sticky-top">
    <div class="row justify-content-center">
      <div class="col-md-9 mb-4">
        <app-callidus-stepper
          [setCurrentPosition]="getCurrentPosition()"
          [stepItems]="stepItems"></app-callidus-stepper>
      </div>
    </div>
  </div>

  @switch (curentNavigationStep) {
    @case (SkipperHaftpflichtNavigationEnum.Base) {
      <app-charter-kautionsversicherung
        (next)="moveToNextStep()"
        (prev)="moveToPreviousStep()"
        [form]="form">
      </app-charter-kautionsversicherung>
    }
    @case (SkipperHaftpflichtNavigationEnum.Customer) {
      <app-customer-data
        (prev)="moveToPreviousStep()"
        (submitted)="submitData()"
        [form]="form.controls.customerData"></app-customer-data>
    }
    @case (SkipperHaftpflichtNavigationEnum.Done) {
      <app-data-submitted></app-data-submitted>
    }
  }
</section>
