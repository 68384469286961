<form
  [formGroup]="form"
  class="container mt-4">
  <div class="row justify-content-center">


    <div class="col-12 col-md-9 col-lg-6">
      @if (filteredSelectOptions.length) {
        <div
          class="mb-5 text-center">
          <label
            class="form-label"
            for="selbstbehaltKunde">
            {{ 'bvs.calculator.selbstbehalt' | transloco }}
          </label>

          <select
            [formControl]="this.form.controls.bvsFigures.controls.selbstbehaltKunde"
            class="form-select form-select-sm text-center"
            data-cy="selbstbehaltKunde"
            id="selbstbehaltKunde">
            @for (option of filteredSelectOptions; track $index) {
              <option
                [ngValue]="option.value">{{ option.display }}
              </option>
            }
          </select>
        </div>
      }
      @if (filteredSelectOptions.length === 0 || this.form.controls.bvsFigures.controls.selbstbehaltKunde.value) {
        <div class="pt-md-3">
          <div class="question-title">
            {{ 'bvs.calculator.additionalInsurance' | transloco }}
          </div>

          <div class="mb-4">
            <app-additional-coverage-box
              [ctrl]="form.controls.bvsBoatInformation.controls.isCharter"
              [icon]="'pi-users'"
              [text]="'bvs.calculator.isCharterText'"
              [title]="'bvs.calculator.isCharterTitle'"></app-additional-coverage-box>
          </div>

          @if (isBoatLessThan10YearsOld()) {
            <div class="mb-4">
              <app-additional-coverage-box
                [ctrl]="form.controls.bvsFigures.controls.isMaschinendeckung"
                [icon]="'pi-wrench'"
                [text]="'bvs.calculator.isMaschinendeckungText'"
                [title]="'bvs.calculator.isMaschinendeckungTitle'"></app-additional-coverage-box>
            </div>
          }
          @if (isSailingBoat()) {
            <div
              class="mb-4">
              <app-additional-coverage-box
                [ctrl]="form.controls.bvsBoatInformation.controls.isSport"
                [icon]="'pi-flag'"
                [text]="'bvs.calculator.isSportText'"
                [title]="'bvs.calculator.isSportTitle'"></app-additional-coverage-box>
            </div>
          }

          <div class="mb-4">
            <app-additional-coverage-box-for-enum
              [ctrl]="form.controls.bvsFigures.controls.bvsNepKunde"
              [switchValues]="[BvsNepKundeEnum.NEP5MIO, BvsNepKundeEnum.NEP10MIO]"
              [icon]="'pi-shield'"
              [text]="'bvs.calculator.bvsNepKundeText'"
              [title]="'bvs.calculator.bvsNepKundeTitle'"></app-additional-coverage-box-for-enum>
          </div>

          <div class="mb-4">
            <app-additional-coverage-box
              [ctrl]="form.controls.bvsFigures.controls.isRechtsschutz"
              [icon]="'pi-id-card'"
              [text]="'bvs.calculator.isRechtsschutzText'"
              [title]="'bvs.calculator.isRechtsschutzTitle'"></app-additional-coverage-box>
          </div>

          @if (form.controls.bvsFigures.controls.isRechtsschutz.value) {
            <div class="mb-4">
              <app-additional-coverage-box
                [ctrl]="form.controls.bvsFigures.controls.isKeinSelbstbehaltBeiRechtsschutz"
                [icon]="'pi-id-card'"
                [text]="'bvs.calculator.isSelbstbehaltText'"
                [title]="'bvs.calculator.isSelbstbehaltTitle'"></app-additional-coverage-box>
            </div>
          }

          <div
            [ngClass]="getPremiumColorCode(this.calculationResult)"
            class="card-body mb-4 box-shadow">

            @if (showInsurancePremium()) {
              <ng-container
                class="mb-5">
                <app-premium-display
                  [additionalHint]="form.controls.bvsFigures.controls.isRechtsschutz.value === true ? ('bvs.calculator.isRechtsschutzHint'|transloco) : null"
                  [premiumValue]="calculationResult?.result!"></app-premium-display>
              </ng-container>
            }
            @if (showErrorMessages()) {
              <app-calculation-error-display
                [result]="calculationResult"></app-calculation-error-display>
            }
          </div>
        </div>
      }

      <div class="mb-5 d-flex justify-content-center">
        <button
          (click)="prev.emit()"
          class="btn btn-sm btn-secondary">
          {{ 'shared.back' | transloco }}
        </button>

        @if (this.form.controls.bvsFigures.controls.selbstbehaltKunde.value || filteredSelectOptions.length === 0) {
          <button
            (click)="next.emit()"
            data-cy="continueToCustomerData"
            class="btn btn-sm btn-primary">
            {{ 'bvs.calculator.continueToCustomerData' | transloco }}
          </button>
        }
      </div>
    </div>
  </div>
</form>
