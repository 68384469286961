<section class="container-fluid position-relative">
  <div class="container sticky-top">
    <div class="row justify-content-center">
      <div class="col-md-9 mb-4">
        <app-callidus-stepper
          [setCurrentPosition]="getCurrentPosition()"
          [stepItems]="stepItems"></app-callidus-stepper>
      </div>
    </div>
  </div>

    @switch (curentNavigationStep) {
      @case (HaftpflichtNavigationEnum.Base) {
        <app-boat-data-haftpflicht
          (next)="moveToNextStep()"
          (prev)="moveToPreviousStep()"
          [form]="form"></app-boat-data-haftpflicht>
      }
      @case (HaftpflichtNavigationEnum.AdditionalCoverage) {
        <app-additional-coverage-haftpflicht-data
          (next)="moveToNextStep()"
          (prev)="moveToPreviousStep()"
          [form]="form"></app-additional-coverage-haftpflicht-data>
      }
      @case (HaftpflichtNavigationEnum.Customer) {
        <app-customer-data
          (prev)="moveToPreviousStep()"
          (submitted)="submitData()"
          [form]="form.controls.customerData"></app-customer-data>
      }
      @case (HaftpflichtNavigationEnum.Done) {
        <app-data-submitted></app-data-submitted>
      }
  }
</section>
