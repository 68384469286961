<form
  [formGroup]="form"
  class="container">
  <div class="row justify-content-center">
    <div class="col-12">
      <div class="mb-5">
        <app-question-box
          (answerSelect)="setValueToForm(form.controls.country, $event.value)"
          [answers]="countryAnswerOptions"
          [id]="'country'"
          [selectedValue]="form.controls.country.value"
          [title]="'bvs.calculator.person.country'"></app-question-box>
      </div>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-12 col-md-9 col-lg-6">
      @if (form.controls.country.value !== null) {
        <app-skipper-haftpflicht [form]="form">
        </app-skipper-haftpflicht>
        <app-skipper-rechtsschutz [form]="form">
        </app-skipper-rechtsschutz>
      }

      <div
        [ngClass]="getPremiumColorCode(skipperCalculationService.calculationResult)"
        class="card-body mb-4 box-shadow">
        @if (canShowCalculationsResult()) {
          <div
            class="mb-5">
            <app-premium-display
              [premiumValue]="skipperCalculationService.calculationResult?.result!"></app-premium-display>
          </div>
        }
      </div>

      <div class="mb-5 mt-5 d-flex justify-content-center">
        <button
          (click)="prev.emit()"
          class="btn btn-sm btn-secondary">
          {{ 'shared.back' | transloco }}
        </button>

        @if (this.form.controls.hasHaftpflicht.value || this.form.controls.hasRechtsschutz.value) {
          <button
            (click)="next.emit()"
            data-cy="continueToContract"
            class="btn btn-sm btn-primary">
            @if (this.form.controls.hasHaftpflicht.value) {
              {{ 'bvs.calculator.continueToHaftpflichtDetails' | transloco }}
            } @else {
              {{ 'bvs.calculator.continueToRechtsschutzDetails' | transloco }}
            }
          </button>
        }
      </div>
    </div>
  </div>
</form>
