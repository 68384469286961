import { EnumLabelKeysModel } from '@brokernet/brokernet-ui-lib';

export enum CountryRegisteredEnum {
  AUT = 'AUT',
  GER = 'GER',
  CRO = 'CRO',
  SLO = 'SLO'
}

export namespace CountryRegisteredValues {
  export function getLabelKeys(): EnumLabelKeysModel<CountryRegisteredEnum>[] {
    return [
      { key: CountryRegisteredEnum.AUT, label: 'bvs.calculator.oesterreich' },
      { key: CountryRegisteredEnum.GER, label: 'bvs.calculator.deutschland' },
      { key: CountryRegisteredEnum.CRO, label: 'bvs.calculator.kroatien' },
      { key: CountryRegisteredEnum.SLO, label: 'bvs.calculator.slowenien' }
    ];
  }

  export function getEnumByKey(key: CountryRegisteredEnum): EnumLabelKeysModel<CountryRegisteredEnum> | undefined {
    return getLabelKeys().find(k => k.key === key);
  }
}
