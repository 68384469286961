import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-question-box',
  standalone: true,
  imports: [
    TranslocoPipe,
    NgClass
  ],
  templateUrl: './question-box.component.html',
  styleUrl: './question-box.component.scss'
})
export class QuestionBoxComponent<T> implements OnInit {

  @Input() title!: string;
  @Input() answers!: AnswerOption<T>[];
  @Input() selectedValue!: null | T;
  @Input() id!:string;
  @Output() answerSelect = new EventEmitter<AnswerOption<T>>();

  selectedAnswer: AnswerOption<T> | null = null;

  ngOnInit(): void {
    if (this.selectedValue !== null) {
      this.selectedAnswer = this.answers.find(x => x.value === this.selectedValue)!;
    }
  }

  setSelectedAnswer(answerOption: AnswerOption<T>): void {
    if (answerOption.isDisabled) {
      return;
    }

    this.selectedAnswer = answerOption;
    this.answerSelect.emit(answerOption);
  }
}

export interface AnswerOption<T> {
  id: string | T;
  title: string;
  value: T;
  isDisabled?: boolean;
}
