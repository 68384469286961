import { Component, Input } from '@angular/core';
import { SkipperVersicherungssummeKundeEnum } from '@shared/models/skipper-versicherungssumme-kunde.enum';
import { AdditionalCoverageBoxForEnumComponent } from '@shared/components/additional-coverage-for-enum/additional-coverage-box-for-enum.component';
import { SkipperFormModel } from '@shared/models/forms/skipper-form.model';
import { FormGroup } from '@angular/forms';
import { AdditionalCoverageBoxComponent } from '@shared/components/additional-coverage-box/additional-coverage-box.component';

@Component({
  selector: 'app-skipper-haftpflicht',
  standalone: true,
  templateUrl: './skipper-haftpflicht.component.html',
  imports: [
    AdditionalCoverageBoxForEnumComponent,
    AdditionalCoverageBoxComponent
  ],
  styleUrl: './skipper-haftpflicht.component.scss'
})
export class SkipperHaftpflichtComponent {
  @Input() form!: FormGroup<SkipperFormModel>;
  protected readonly SkipperSelbstbehaltKundeEnum = SkipperVersicherungssummeKundeEnum;
}
