<div class="pricing-data-box pointer">
  <div class="content row">
    <div class="texts col-12 col-md-6">
      <div class="title">{{ title | transloco }}</div>
      <div class="text">{{ text | transloco }}</div>
    </div>

    <div class="col-12 col-md-6">
      <p-inputNumber
        [currency]="'EUR'"
        [formControl]="control"
        [inputId]="inputId"
        [locale]="'de-DE'"
        [mode]="'currency'"></p-inputNumber>
    </div>

    <ng-content></ng-content>
  </div>
</div>
