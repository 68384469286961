import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment/environment';
import { Observable } from 'rxjs';
import { CalculationResultModel } from '@shared/models/calculation-result.model';
import { GetBvsVersicherungsPraemieCommandModel } from '@shared/models/commands/get-versicherungs-praemie-charter-kautionsversicherung-command.model';

@Injectable()
export class BvsHaftpflichtApiService {

  http = inject(HttpClient);

  get endpoint(): string {
    return environment.basePath + '/api/bvs/public';
  }

  calculate(getBvsVersicherungsPraemieCommand: GetBvsVersicherungsPraemieCommandModel): Observable<CalculationResultModel> {
    return this.http.post<CalculationResultModel>(`${this.endpoint}/haftplicht/versicherungs-praemie`, getBvsVersicherungsPraemieCommand);
  }

}
