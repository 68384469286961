import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment/environment';
import { Observable } from 'rxjs';
import { BvsCharterKautionsversicherungModel } from '../models/bvs-charter-kautionsversicherung.model';
import { CharterCalculationResultModel } from '@shared/models/charter-calculation-result.model';
import { SubmitCharterKautionsversicherungCommandModel } from '@shared/models/commands/submit-customer-command.model';

@Injectable()
export class CharterkautionApiService {

  http = inject(HttpClient);

  get endpoint(): string {
    return environment.basePath + '/api/charter/public';
  }

  calculate(bvsCharterKautionsversicherungModel: BvsCharterKautionsversicherungModel): Observable<CharterCalculationResultModel> {
    return this.http.post<CharterCalculationResultModel>(`${this.endpoint}/versicherungs-praemie`, bvsCharterKautionsversicherungModel);
  }

  submit(command: SubmitCharterKautionsversicherungCommandModel): Observable<Object> {
    return this.http.post<Object>(`${this.endpoint}/onboarding`, command);
  }

}
