import { inject, Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
  name: 'callidusCurrency',
  standalone: true
})
export class CallidusCurrencyPipe implements PipeTransform {

  currencyPipe = inject(CurrencyPipe);

  transform(value: number | string, display: 'symbol' | 'code' = 'symbol'): string | null {
    return this.currencyPipe.transform(value, 'EUR', display, '1.2-2', 'de-DE');
  }
}
