import { EnumLabelKeysModel } from '@brokernet/brokernet-ui-lib';

export enum MastMaterialEnum {
  ALUMINIUM = 'ALUMINIUM',
  HOLZ = 'HOLZ',
  CARBON = 'CARBON',
  ANDERE = 'ANDERE'
}

export namespace MastMaterialEnumValues {
  export function getLabelKeys(): EnumLabelKeysModel<MastMaterialEnum>[] {
    return [
      { key: MastMaterialEnum.ALUMINIUM, label: 'bvs.aluminum' },
      { key: MastMaterialEnum.HOLZ, label: 'bvs.holz' },
      { key: MastMaterialEnum.CARBON, label: 'bvs.carbon' },
      { key: MastMaterialEnum.ANDERE, label: 'bvs.andere' }
    ];
  }

  export function getEnumByKey(key: MastMaterialEnum): EnumLabelKeysModel<MastMaterialEnum> | undefined {
    return getLabelKeys().find(k => k.key === key);
  }
}
