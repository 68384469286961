import { ChangeDetectorRef, Directive, EventEmitter, inject, OnDestroy, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { unsubscribeMixin } from '@shared/mixin/unsubscribe.mixin';
import { CalculationResultStatusEnum } from '@shared/models/calculation-result-status.enum';
import { CalculationResultModel } from '@shared/models/calculation-result.model';

@Directive()
export class DataBaseComponent extends unsubscribeMixin() implements OnDestroy {

  changeDetection=inject(ChangeDetectorRef);

  @Output() next = new EventEmitter<void>();
  @Output() prev = new EventEmitter<void>();
  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  getPremiumColorCode(calculationResult: CalculationResultModel | null): string {
    switch (calculationResult?.calculationResultStatus) {
      case CalculationResultStatusEnum.OK:
        return 'green';
      case CalculationResultStatusEnum.OK_WITH_WARNING:
        return 'yellow';
      case CalculationResultStatusEnum.ERROR:
        return 'red';
    }

    return 'white';
  }

  protected setValueToForm<T>(control: FormControl<T>, value: T): void {
    control.setValue(value);
    this.changeDetection.detectChanges();
  }
}
