import { Component, Input, OnInit } from '@angular/core';
import { SkipperHaftpflichtComponent } from '../skipper-haftpflicht/skipper-haftpflicht.component';
import { AdditionalCoverageBoxComponent } from '@shared/components/additional-coverage-box/additional-coverage-box.component';
import { SkipperFormModel } from '@shared/models/forms/skipper-form.model';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { PaginatorModule } from 'primeng/paginator';
import { TranslocoPipe } from '@ngneat/transloco';
import { takeUntil } from 'rxjs';
import { unsubscribeMixin } from '@shared/mixin/unsubscribe.mixin';

@Component({
  selector: 'app-skipper-rechtsschutz',
  standalone: true,
  templateUrl: './skipper-rechtsschutz.component.html',
  imports: [
    SkipperHaftpflichtComponent,
    AdditionalCoverageBoxComponent,
    PaginatorModule,
    TranslocoPipe,
    ReactiveFormsModule
  ],
  styleUrl: './skipper-rechtsschutz.component.scss'
})
export class SkipperRechtsschutzComponent extends unsubscribeMixin() implements OnInit {
  @Input() form!: FormGroup<SkipperFormModel>;

  ngOnInit(): void {
    this.form.controls.skipperRechtsschutz.controls.hasCharterVertragsRechtsschutz
      .valueChanges.pipe(takeUntil(this.componentDestroyed$)).subscribe((value) => {
      if (value) {
        this.form.controls.skipperRechtsschutz.controls.hasSelbstbehalt.setValue(true);
        this.form.controls.skipperRechtsschutz.controls.hasSelbstbehalt.disable();
      } else {
        this.form.controls.skipperRechtsschutz.controls.hasSelbstbehalt.enable();
      }
    });
  }
}