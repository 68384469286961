import { EnumLabelKeysModel } from '@brokernet/brokernet-ui-lib';

export enum SailingAreaEnum {
  BINNENSEE = 'BINNENSEE',
  MITTELMEER_NORD_UND_OSTSEE = 'MITTELMEER_NORD_UND_OSTSEE'
}

export namespace SailingAreaValues {
  export function getLabelKeys(): EnumLabelKeysModel<SailingAreaEnum>[] {
    return [
      { key: SailingAreaEnum.BINNENSEE, label: 'bvs.calculator.sailingAreaBinnensee' },
      { key: SailingAreaEnum.MITTELMEER_NORD_UND_OSTSEE, label: 'bvs.calculator.mittelmeerNordOstsee' }
    ];
  }

  export function getEnumByKey(key: SailingAreaEnum): EnumLabelKeysModel<SailingAreaEnum> | undefined {
    return getLabelKeys().find(k => k.key === key);
  }
}
