import { FormControl } from '@angular/forms';
import { BvsNepKundeEnum } from '@shared/models/bvs-nep-kunde.enum';
import { BvsBaseFiguresFormModel } from '@shared/models/forms/bvs-base-figures-form.model';

export function createBvsFiguresBaseForm(): BvsBaseFiguresFormModel {
  return {
    isRechtsschutz: new FormControl<boolean | null>(null),
    isKeinSelbstbehaltBeiRechtsschutz: new FormControl<boolean | null>(null),
    bvsNepKunde: new FormControl<BvsNepKundeEnum | null>(BvsNepKundeEnum.NEP5MIO)
  };
}
