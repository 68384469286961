<div class="premium-wrapper">
  <div
    class="header"
    style="margin-bottom: 0.5rem;">
    @if (isFixedRate) {
      {{ 'bvs.calculator.calculatedDefinitivePremium' | transloco }}
    } @else {
      {{ 'bvs.calculator.calculatedPremium' | transloco }}
    }
  </div>

  <div class="premium">
    {{ premiumValue | callidusCurrency }}
  </div>

  @if (additionalHint) {
    <small
      class="text-muted mt-2">
      {{ additionalHint }}
    </small>
  }
</div>
