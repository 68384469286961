import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BvsHaftpflichtFormModel } from '../../features/bvs-haftpflicht/models/form/bvs-haftpflicht-form.model';
import { BvsHaftpflichtUndKaskoFormModel } from '../../features/bvs-haftpflicht-kasko/models/form/bvs-haftpflicht-und-kasko-form.model';
import { SkipperFormModel } from '@shared/models/forms/skipper-form.model';
import {
  BvsCharterKautionsversicherungFormModel
} from '../../features/bvs-charter-kautionsversicherung/models/forms/bvs-charter-kautionsversicherung-form.model';
import { environment } from '@environment/environment';

@Injectable({ providedIn: 'root' })
export class FormService {

  private formData: FormGroup<any> | null = null;

  setFormData(data: FormGroup<any>): void {
    this.formData = data;
  }

  getHaftpflichtKaskoFormData(): FormGroup<BvsHaftpflichtUndKaskoFormModel> {
    return this.formData as FormGroup<BvsHaftpflichtUndKaskoFormModel>;
  }

  getHaftpflichtFormData(): FormGroup<BvsHaftpflichtFormModel> {
    return this.formData as FormGroup<BvsHaftpflichtFormModel>;
  }

  getSkipperFormData(): FormGroup<SkipperFormModel> {
    return this.formData as FormGroup<SkipperFormModel>;
  }

  getCharterKautionsversicherungFormData(): FormGroup<BvsCharterKautionsversicherungFormModel> {
    return this.formData as FormGroup<BvsCharterKautionsversicherungFormModel>;
  }

  isFormDataSet(): boolean {
    if (!environment.featureToggles.enableFormStorage) {
      return false;
    }

    return !!this.formData;
  }
}
