import { FormControl, FormGroup, Validators } from '@angular/forms';

import { SkipperRechtsschutzFormModel } from '../models/forms/skipper-rechtsschutz-form.model';

export function createSkipperRechtsschutzForm(): FormGroup<SkipperRechtsschutzFormModel> {
  return new FormGroup<SkipperRechtsschutzFormModel>({
    hasSelbstbehalt: new FormControl<boolean>(false, Validators.required),
    hasCharterVertragsRechtsschutz: new FormControl<boolean>(false, Validators.required),
    isSailAreaExplicitlyInEurope: new FormControl<boolean | null>(null),
    isBoatExplicitlyUsedPrivately: new FormControl<boolean | null>(null),
    hasAlreadyHadLegalProtectionInsurance: new FormControl<boolean | null>(null),
    hasAlreadyHadDamagesOrLegalDispute: new FormControl<boolean | null>(null),
    hasAlreadyHadDamagesOrLegalDisputeDescription: new FormControl<string | null>(null)
  });

}




