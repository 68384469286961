import { Component, Input } from '@angular/core';
import { CalculationResultStatusEnum } from '@shared/models/calculation-result-status.enum';
import { TranslocoPipe } from '@ngneat/transloco';
import { JsonPipe } from '@angular/common';
import { CalculationResultModel } from '@shared/models/calculation-result.model';

@Component({
  selector: 'app-calculation-error-display',
  standalone: true,
  imports: [
    TranslocoPipe,
    JsonPipe
  ],
  templateUrl: './calculation-error-display.component.html',
  styleUrl: './calculation-error-display.component.scss'
})
export class CalculationErrorDisplayComponent {

  @Input() result!: CalculationResultModel | null;

  protected readonly CalculationResultStatusEnum = CalculationResultStatusEnum;
}
