import { FormControl, FormGroup } from '@angular/forms';
import { CustomerFormModel } from '@shared/models/forms/customer-form.model';
import { createPersonForm } from './person-form.factory';
import { createLegalCheckForm } from './legal-check-form.factory';

export function createCustomerDataForm(): FormGroup<CustomerFormModel> {
  return new FormGroup<CustomerFormModel>({
    person: createPersonForm(),
    legalChecks: createLegalCheckForm(),
    customerComment: new FormControl<string | null>(null)
  });
}

