import { EnumLabelKeysModel } from '@brokernet/brokernet-ui-lib';

export enum HullMaterialEnum {
  GFK = 'GFK',
  HOLZ = 'HOLZ',
  CARBON = 'CARBON',
  STAHL = 'STAHL',
  ANDERE = 'ANDERE'
}

export namespace HullMaterialEnumValues {
  export function getLabelKeys(): EnumLabelKeysModel<HullMaterialEnum>[] {
    return [
      { key: HullMaterialEnum.GFK, label: 'bvs.gfk' },
      { key: HullMaterialEnum.HOLZ, label: 'bvs.holz' },
      { key: HullMaterialEnum.CARBON, label: 'bvs.carbon' },
      { key: HullMaterialEnum.STAHL, label: 'bvs.stahl' },
      { key: HullMaterialEnum.ANDERE, label: 'bvs.andere' }
    ];
  }

  export function getEnumByKey(key: HullMaterialEnum): EnumLabelKeysModel<HullMaterialEnum> | undefined {
    return getLabelKeys().find(k => k.key === key);
  }
}
