import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SubmitSkipperCommandModel } from '@shared/models/commands/submit-customer-command.model';
import { environment } from '@environment/environment';
import { GetVersicherungsPraemieSkipperCommandModel } from '@shared/models/commands/get-versicherungs-praemie-command.model';
import { SkipperCalculationResultModel } from '@shared/models/skipper-calculation-result.model';

@Injectable({ providedIn: 'root' })
export class SkipperApiService {

  http = inject(HttpClient);

  get endpoint(): string {
    return environment.basePath + '/api/skipper/public';
  }

  submit(command: SubmitSkipperCommandModel): Observable<Object> {
    return this.http.post<Object>(`${this.endpoint}/onboarding`, command);
  }

  calculate(getBvsVersicherungsPraemieSkipperCommand: GetVersicherungsPraemieSkipperCommandModel): Observable<SkipperCalculationResultModel> {
    return this.http.post<SkipperCalculationResultModel>(`${this.endpoint}/versicherungs-praemie`, getBvsVersicherungsPraemieSkipperCommand);
  }

}
