<div
  class="legal-check-box pointer">

  <div
    (click)="ctrl.setValue(!ctrl.value)"
    class="icon">

    <div class="checkbox">
      <div class="form-check">
        <input
          [attr.data-cy]="inputId"
          [formControl]="ctrl"
          class="form-check-input"
          id="checkboxNoLabel"
          type="checkbox" />
      </div>
    </div>
  </div>

  <div class="content">
    <div class="texts">
      <div
        (click)="ctrl.setValue(!ctrl.value)"
        class="title">{{ title | transloco }}
      </div>

      @if (downloadLink) {
        <div
          class="download-link">
          <a
            [href]="downloadLink"
            target="_blank">{{ 'onboarding.legalChecks.downloadHere' | transloco }}</a>
        </div>
      }
    </div>
  </div>
</div>
