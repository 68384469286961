<form
  [formGroup]="form"
  class="container">

  <div class="row justify-content-center">
    <div class="col-12 col-md-8">
      <div class="mb-4">
        <app-pricing-data-box
          [control]="form.controls.bvsFigures.controls.insuranceSumBoat"
          [inputId]="'insuranceSumBoat'"
          [text]="'bvs.calculator.finance.boat.text'"
          [title]="'bvs.calculator.finance.boat.title'"></app-pricing-data-box>
      </div>

      <div class="mb-4">
        <app-pricing-data-box
          [control]="form.controls.bvsFigures.controls.insuranceSumAdditionalEquipment"
          [inputId]="'insuranceSumAdditionalEquipment'"
          [text]="'bvs.calculator.finance.additional.text'"
          [title]="'bvs.calculator.finance.additional.title'"></app-pricing-data-box>
      </div>

      <div class="mb-4">
        <app-pricing-data-box
          [control]="form.controls.bvsFigures.controls.insuranceSumPersoenlicheEffekte"
          [inputId]="'insuranceSumPersoenlicheEffekte'"
          [text]="'bvs.calculator.finance.persoenlicheEffekte.text'"
          [title]="'bvs.calculator.finance.persoenlicheEffekte.title'"></app-pricing-data-box>
      </div>


      @if (!!form.controls.bvsBoatInformation.controls.hasDinghy.value) {
        <div class="mb-4">
          <app-pricing-data-box
            [control]="form.controls.bvsFigures.controls.insuranceSumDinghy"
            [inputId]="'insuranceSumDinghy'"
            [text]="'bvs.calculator.finance.dinghy.text'"
            [title]="'bvs.calculator.finance.dinghy.title'">
          </app-pricing-data-box>
        </div>
      }

      <div class="mb-4">
        <app-pricing-data-box
          [control]="form.controls.bvsFigures.controls.insuranceSumTrailer"
          [inputId]="'insuranceSumTrailer'"
          [text]="'bvs.calculator.finance.trailer.text'"
          [title]="'bvs.calculator.finance.trailer.title'"></app-pricing-data-box>
      </div>

      <div class="mb-4">
        <div class="pricing-data-box pointer">
          <div class="content">
            <div class="texts">
              <div class="title">
                {{ 'bvs.calculator.finance.total.title' | transloco }}
              </div>
            </div>

            <div class="text-success fw-medium">
              {{ form.controls.bvsFigures.controls.totalInsuranceSum.value | currency:'EUR':'code':'1.2-2':'de-AT' }}
            </div>
          </div>

          <div class="additional-check">
            <div class="checkbox">
              <div class="form-check">
                <input
                  [formControl]="form.controls.bvsFigures.controls.allPricesWithVAT"
                  class="form-check-input"
                  id="allPricesWithVat"
                  type="checkbox" />

                <label
                  class="form-check-label"
                  for="allPricesWithVat">

                  {{ 'bvs.calculator.inclVAT' | transloco }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mb-5 mt-5 d-flex justify-content-center">
      <button
        (click)="prev.emit()"
        class="btn btn-sm btn-secondary">
        {{ 'shared.back' | transloco }}
      </button>

      <button
        (click)="nextStep()"
        class="btn btn-sm btn-primary"
        data-cy="toPremiumCalculation">
        {{ 'bvs.calculator.toPremiumCalculation' | transloco }}
      </button>
    </div>
  </div>
</form>
