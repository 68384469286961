<div class="app-wrapper d-flex flex-column h-100">
  <header class="container">
    <div class="row w-100">
      <div class="col-3">&nbsp;</div>

      <div class="col-6 justify-content-center d-flex">
        <img
          [routerLink]="'/'"
          alt="callidus-logo"
          height="50"
          src="assets/logo.png">
      </div>

      <div class="col-3 d-flex align-items-center justify-content-center">
        <app-language-picker
          (languageChange)="setSelectedLanguage($event)"
          [languageOptions]="languageOptions"
          [selectedLanguage]="selectedLanguage"></app-language-picker>
      </div>
    </div>
  </header>

  <main class="container-xl h-100">
    <div class="row justify-content-center">
      <div class="col-12">
        @if (selectedContractOption === null) {
          <div class="my-5">
            <h3 class="text-center">{{ 'bvs.calculator.title' | transloco }}</h3>
            <p class="sub-title">{{ 'bvs.calculator.subtitle' | transloco }}</p>

            <app-question-box
              [id]="'contract-type'"
              (answerSelect)="routeToContractPage($event.value)"
              [answers]="insuranceTypeAnswerOptions"></app-question-box>
          </div>
        }

        @if (selectedContractOption !== null) {
          <router-outlet></router-outlet>
        }
      </div>
    </div>
  </main>
</div>
