<div
  (click)="toggleSwitchValueFromContainer()"
  class="additional-coverage-box pointer">

  <div class="icon">
    <i
      [ngClass]="icon"
      class="pi"></i>
  </div>

  <div class="content">
    <div class="texts">
      <div class="title">{{ title | transloco }}</div>
      <div class="text">{{ text | transloco }}</div>
    </div>

    <div class="checkbox">
      <div class="form-check form-switch">
        <input
          [(ngModel)]="switchValue"
          class="form-check-input pointer"
          id="flexSwitchCheckDefault"
          role="switch"
          type="checkbox">
      </div>
    </div>
  </div>
</div>
