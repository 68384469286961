import { Component, Input, OnInit } from '@angular/core';
import { LegalCheckBoxComponent } from '../legal-check-box/legal-check-box.component';

import { TranslocoPipe } from '@ngneat/transloco';
import { FormControl, FormGroup } from '@angular/forms';
import { LegalChecksFormModel } from '@shared/models/forms/legal-check-form.model';

@Component({
  selector: 'app-legal-checks-data',
  standalone: true,
  imports: [
    LegalCheckBoxComponent,
    TranslocoPipe
  ],
  templateUrl: './legal-checks-data.component.html',
  styleUrl: './legal-checks-data.component.scss'
})
export class LegalChecksDataComponent implements OnInit {

  @Input() form!: FormGroup<LegalChecksFormModel>;

  legalCheckEntries: LegalCheckEntry[] | null = null;

  ngOnInit(): void {
    this.initLegalCheckEntries();
  }

  initLegalCheckEntries(): void {
    this.legalCheckEntries = [
      {
        title: 'onboarding.legalChecks.contactConsent.title',
        id: 'isContactConsent',
        ctrl: this.form.controls.isContactConsent,
        link: '/assets/digital_communication_consent.pdf'
      },
      {
        title: 'onboarding.legalChecks.gdpr.title',
        id: 'isGdprAccepted',
        ctrl: this.form.controls.isGdprAccepted,
        link: 'https://becallidus.com/datenschutz/'
      }
    ];
  }

}

export interface LegalCheckEntry {
  title: string;
  ctrl: FormControl<boolean | null>;
  link?: string;
  id: string;
}
