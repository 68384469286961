<div class="pt-md-3">
  <div class="mb-4">
    <app-additional-coverage-box
      [ctrl]="form.controls.hasHaftpflicht"
      [customSwitchValues]="[false, true]"
      [icon]="'pi-id-card'"
      [id]="'hasHaftpflicht'"
      [text]="'bvs.calculator.hasHaftpflichtText'"
      [title]="'bvs.calculator.hasHaftpflichtTitle'"></app-additional-coverage-box>
  </div>

  @if (form.controls.hasHaftpflicht.value) {
    <div class="mb-5">
      <app-additional-coverage-box-for-enum
        [ctrl]="form.controls.skipperHaftpflicht.controls.skipperVersicherungssummeKunde"
        [switchValues]="[SkipperSelbstbehaltKundeEnum.VS5MIO, SkipperSelbstbehaltKundeEnum.VS10MIO]"
        [icon]="'pi-shield'"
        [text]="'bvs.calculator.skipper.versicherungssummeText'"
        [title]="'bvs.calculator.skipper.versicherungssummeTitle'"></app-additional-coverage-box-for-enum>
    </div>
  }
</div>