import { inject, Injectable } from '@angular/core';
import { Translation, TranslocoLoader } from '@ngneat/transloco';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environment/environment';
import { BrokernetLanguageTypeEnum } from '@brokernet/brokernet-ui-lib';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {

  http = inject(HttpClient);

  get endpoint(): string {
    return environment.basePath + '/i18n';
  }

  getTranslation(lang: BrokernetLanguageTypeEnum): Observable<Translation> {
    return this.http.get<Translation>(`${this.endpoint}/${lang.toLowerCase()}.json`);
  }
}
