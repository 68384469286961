import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import {
  AdditionalCoverageHaftpflichtDataComponent
} from '../../../bvs-haftpflicht/components/additional-coverage-haftpflicht-data/additional-coverage-haftpflicht-data.component';
import { BoatDataHaftpflichtComponent } from '../../../bvs-haftpflicht/components/boat-data-haftpflicht/boat-data-haftpflicht.component';
import { CalldiusStepItem, CallidusStepperComponent } from '@shared/components/callidus-stepper/callidus-stepper.component';
import { CustomerDataComponent } from '@shared/components/customer-data/customer-data.component';
import { DataSubmittedComponent } from '@shared/components/data-submitted/data-submitted.component';
import { FormGroup } from '@angular/forms';
import { unsubscribeMixin } from '@shared/mixin/unsubscribe.mixin';
import { FormService } from '@shared/services/form.service';
import { BoatDataHaftpflichtKaskoComponent } from '../../../bvs-haftpflicht-kasko/components/boat-data-haftpflicht-kasko/boat-data-haftpflicht-kasko.component';
import { createSkipperForm } from '@shared/factories/skipper-form.factory';
import { SkipperFormModel } from '@shared/models/forms/skipper-form.model';
import { SubmitSkipperCommandModel } from '@shared/models/commands/submit-customer-command.model';
import { SkipperApiService } from '@shared/services/skipper-api.service';
import { BrokernetLanguageTypeEnum, Person } from '@brokernet/brokernet-ui-lib';
import { Router } from '@angular/router';
import { SkipperGeneralComponent } from '../../components/skipper-general/skipper-general.component';
import { environment } from '@environment/environment';
import { takeUntil } from 'rxjs';
import { SkipperHaftpflichtDetailsComponent } from '../../components/skipper-haftpflicht-details/skipper-haftpflicht-details.component';
import { SkipperRechtsschutzDetailsComponent } from '../../components/skipper-rechtsschutz-details/skipper-rechtsschutz-details.component';

@Component({
  selector: 'app-skipper-haftpflicht-page',
  standalone: true,
  imports: [
    AdditionalCoverageHaftpflichtDataComponent,
    BoatDataHaftpflichtComponent,
    CallidusStepperComponent,
    CustomerDataComponent,
    DataSubmittedComponent,
    BoatDataHaftpflichtKaskoComponent,
    SkipperGeneralComponent,
    SkipperHaftpflichtDetailsComponent,
    SkipperRechtsschutzDetailsComponent
  ],
  templateUrl: './skipper-page.component.html',
  styleUrl: './skipper-page.component.scss'
})
export class SkipperPageComponent extends unsubscribeMixin() implements OnInit, OnDestroy {
  formService = inject(FormService);
  skipperApiService = inject(SkipperApiService);
  router = inject(Router);

  stepItems: CalldiusStepItem[] = [
    {
      id: SkipperHaftpflichtNavigationEnum.Base.toString(),
      label: 'bvs.calculator.menu.infos',
      iconUrl: '/assets/icons/info-circle.svg',
      enabled: true
    },
    {
      id: SkipperHaftpflichtNavigationEnum.SkipperHaftpflichtDetails.toString(),
      label: 'bvs.calculator.menu.haftpflichtDetails',
      iconUrl: '/assets/icons/info-circle.svg',
      enabled: false
    },
    {
      id: SkipperHaftpflichtNavigationEnum.SkipperRechtsschutzDetails.toString(),
      label: 'bvs.calculator.menu.rechtsschutzDetails',
      iconUrl: '/assets/icons/info-circle.svg',
      enabled: false
    },
    {
      id: SkipperHaftpflichtNavigationEnum.Customer.toString(),
      label: 'bvs.calculator.menu.finish',
      iconUrl: '/assets/icons/health-shield.svg',
      enabled: true
    }
  ];

  curentNavigationStep: SkipperHaftpflichtNavigationEnum = SkipperHaftpflichtNavigationEnum.Base;
  form!: FormGroup<SkipperFormModel>;

  protected readonly SkipperHaftpflichtNavigationEnum = SkipperHaftpflichtNavigationEnum;

  private initialStepOrder: SkipperHaftpflichtNavigationEnum[] = [
    SkipperHaftpflichtNavigationEnum.Base,
    SkipperHaftpflichtNavigationEnum.SkipperHaftpflichtDetails,
    SkipperHaftpflichtNavigationEnum.SkipperRechtsschutzDetails,
    SkipperHaftpflichtNavigationEnum.Customer,
    SkipperHaftpflichtNavigationEnum.Done
  ];

  stepOrder = this.initialStepOrder;

  ngOnInit(): void {
    this.initializeForm();
    this.form.controls.hasRechtsschutz.valueChanges.pipe(takeUntil(this.componentDestroyed$)).subscribe(value => {
      this.updateStepEnabledState(SkipperHaftpflichtNavigationEnum.SkipperRechtsschutzDetails, value!);
    });
    this.form.controls.hasHaftpflicht.valueChanges.pipe(takeUntil(this.componentDestroyed$)).subscribe(value => {
      this.updateStepEnabledState(SkipperHaftpflichtNavigationEnum.SkipperHaftpflichtDetails, value!);
    });
  }

  updateStepEnabledState(stepEnum: SkipperHaftpflichtNavigationEnum, enabled: boolean): void {
    const step = this.stepItems.find(item => item.id === stepEnum.toString());
    if (step) {
      step.enabled = enabled;
    }
    this.updateStepOrder();
  }

  updateStepOrder(): void {
    const steps = this.stepItems
      .filter(item => item.enabled)
      .map(item => {
        return Number(item.id);
      });
    steps.push(SkipperHaftpflichtNavigationEnum.Done);
    this.stepOrder = steps;
  }

  getCurrentPosition(): number {
    return this.stepOrder.indexOf(this.curentNavigationStep);
  }

  override ngOnDestroy(): void {
    if (environment.featureToggles.enableFormStorage) {
      this.formService.setFormData(this.form);
    }
  }

  moveToNextStep(): void {
    this.curentNavigationStep++;
    if (this.isCurrentStepDisabled()) {
      this.moveToNextStep();
    }
  }

  isCurrentStepDisabled(): boolean {
    const currentStep = this.stepItems.find(item => item.id === this.curentNavigationStep.toString());
    return currentStep ? !currentStep.enabled : true;
  }

  moveToPreviousStep(): void {
    if (this.curentNavigationStep === SkipperHaftpflichtNavigationEnum.Base) {
      this.router.navigate(['/']);
    }

    this.curentNavigationStep--;
    if (this.isCurrentStepDisabled()) {
      this.moveToPreviousStep();
    }
  }

  submitData(): void {
    const ctrls = this.form.controls;
    const submitSkipperCmd: SubmitSkipperCommandModel = {
      person: {
        ...ctrls.customerData.controls.person.getRawValue(),
        titlePrefix: null,
        titleSuffix: null,
        language: localStorage.getItem('app-language') === BrokernetLanguageTypeEnum.EN ? BrokernetLanguageTypeEnum.EN : BrokernetLanguageTypeEnum.DE
      } as unknown as NonNullable<Person>,
      country: ctrls.country.value,
      customerComment: ctrls.customerData.controls.customerComment.value!,

      //Haftpflicht
      skipperVersicherungssummeKunde: ctrls.hasHaftpflicht.value ? ctrls.skipperHaftpflicht.controls.skipperVersicherungssummeKunde.value : null,
      hasBeantragteRisikenGekuendigtOderAbgelehnt: ctrls.hasHaftpflicht.value ? ctrls.skipperHaftpflicht.controls.hasBeantragteRisikenGekuendigtOderAbgelehnt.value : null,
      versicherungsgesellschaftGekuendigtOderAbgelehnt: ctrls.hasHaftpflicht.value ? ctrls.skipperHaftpflicht.controls.versicherungsgesellschaftGekuendigtOderAbgelehnt.value : null,
      yearGekuendigtOderAbgelehnt: ctrls.hasHaftpflicht.value ? ctrls.skipperHaftpflicht.controls.yearGekuendigtOderAbgelehnt.value : null,
      policyNumberGekuendigtOderAbgelehnt: ctrls.hasHaftpflicht.value ? ctrls.skipperHaftpflicht.controls.policyNumberGekuendigtOderAbgelehnt.value : null,
      sparteGekuendigtOderAbgelehnt: ctrls.hasHaftpflicht.value ? ctrls.skipperHaftpflicht.controls.sparteGekuendigtOderAbgelehnt.value : null,
      grundGekuendigtOderAbgelehnt: ctrls.hasHaftpflicht.value ? ctrls.skipperHaftpflicht.controls.grundGekuendigtOderAbgelehnt.value : null,

      hasSchaedenBereitsErlitten: ctrls.hasHaftpflicht.value ? ctrls.skipperHaftpflicht.controls.hasSchaedenBereitsErlitten.value : null,
      hasRisikenBereitsVersichert: ctrls.hasHaftpflicht.value ? ctrls.skipperHaftpflicht.controls.hasRisikenBereitsVersichert.value : null,

      //Rechtschutz
      hasSelbstbehalt: ctrls.hasRechtsschutz.value ? ctrls.skipperRechtsschutz.controls.hasSelbstbehalt.value : null,
      hasCharterVertragsRechtsschutz: ctrls.hasRechtsschutz.value ? ctrls.skipperRechtsschutz.controls.hasCharterVertragsRechtsschutz.value : null,
      isSailAreaExplicitlyInEurope: ctrls.hasRechtsschutz.value ? ctrls.skipperRechtsschutz.controls.isSailAreaExplicitlyInEurope.value : null,
      isBoatExplicitlyUsedPrivately: ctrls.hasRechtsschutz.value ? ctrls.skipperRechtsschutz.controls.isBoatExplicitlyUsedPrivately.value : null,
      hasAlreadyHadLegalProtectionInsurance: ctrls.hasRechtsschutz.value ? ctrls.skipperRechtsschutz.controls.hasAlreadyHadLegalProtectionInsurance.value : null,
      hasAlreadyHadDamagesOrLegalDispute: ctrls.hasRechtsschutz.value ? ctrls.skipperRechtsschutz.controls.hasAlreadyHadDamagesOrLegalDispute.value : null,
      hasAlreadyHadDamagesOrLegalDisputeDescription: ctrls.hasRechtsschutz.value ? ctrls.skipperRechtsschutz.controls.hasAlreadyHadDamagesOrLegalDisputeDescription.value : null
    };

    this.skipperApiService.submit(submitSkipperCmd).subscribe();
    this.curentNavigationStep = SkipperHaftpflichtNavigationEnum.Done;
  }

  getStepItems(): CalldiusStepItem[] {
    return this.stepItems.filter(x => x.enabled);
  }

  private initializeForm(): void {
    this.form = createSkipperForm();
    if (this.formService.isFormDataSet()) {
      this.form.patchValue(this.formService.getSkipperFormData().getRawValue());
    }
  }

}

export enum SkipperHaftpflichtNavigationEnum {
  Base = 0,
  SkipperHaftpflichtDetails = 1,
  SkipperRechtsschutzDetails = 2,
  Customer = 3,
  Done = 4
}