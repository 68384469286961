import { Component, inject, Input, OnInit } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslocoPipe } from '@ngneat/transloco';
import { AdditionalCoverageBoxComponent } from '@shared/components/additional-coverage-box/additional-coverage-box.component';
import { QuestionBoxComponent } from '@shared/components/question-box/question-box.component';
import { defaultAnswerOptions } from '@shared/models/default-answer-options';
import { PremiumDisplayComponent } from '@shared/components/premium-display/premium-display.component';
import { NgClass } from '@angular/common';
import { takeUntil } from 'rxjs';
import { CalculationErrorDisplayComponent } from '@shared/components/calculation-error-display/calculation-error-display.component';
import { DataBaseComponent } from '@shared/directives/data-base.component';
import { SkipperCalculationService } from '../../services/skipper-calculation.service';
import { SkipperFormModel } from '@shared/models/forms/skipper-form.model';

@Component({
  selector: 'app-skipper-haftpflicht-details',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TranslocoPipe,
    AdditionalCoverageBoxComponent,
    QuestionBoxComponent,
    PremiumDisplayComponent,
    NgClass,
    CalculationErrorDisplayComponent
  ],
  templateUrl: './skipper-haftpflicht-details.component.html',
  styleUrl: './skipper-haftpflicht-details.component.scss'
})
export class SkipperHaftpflichtDetailsComponent extends DataBaseComponent implements OnInit {

  skipperCalculationService = inject(SkipperCalculationService);

  @Input() form!: FormGroup<SkipperFormModel>;

  protected readonly defaultAnswerOptions = defaultAnswerOptions;

  ngOnInit(): void {
    this.calculate();
    this.form.valueChanges.pipe(takeUntil(this.componentDestroyed$)).subscribe(() => this.calculate());
  }

  canShowHasSchaedenBereitsErlitten(): boolean {
    return this.form.controls.skipperHaftpflicht.controls.hasBeantragteRisikenGekuendigtOderAbgelehnt.value === false ||
      (!!this.form.controls.skipperHaftpflicht.controls.hasBeantragteRisikenGekuendigtOderAbgelehnt.value &&
        this.isHasBeantragteRisikenGekuendigtOderAbgelehntValid());
  }

  calculate(): void {

    this.skipperCalculationService
      .calculate(this.form);
  }

  isHasBeantragteRisikenGekuendigtOderAbgelehntValid(): boolean {
    return !!this.form.controls.skipperHaftpflicht.controls.versicherungsgesellschaftGekuendigtOderAbgelehnt.value &&
      !!this.form.controls.skipperHaftpflicht.controls.yearGekuendigtOderAbgelehnt.value &&
      !!this.form.controls.skipperHaftpflicht.controls.policyNumberGekuendigtOderAbgelehnt.value &&
      !!this.form.controls.skipperHaftpflicht.controls.sparteGekuendigtOderAbgelehnt.value &&
      !!this.form.controls.skipperHaftpflicht.controls.grundGekuendigtOderAbgelehnt.value;
  }
}
