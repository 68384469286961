import { Component, Input } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoPipe } from '@ngneat/transloco';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-legal-check-box',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TranslocoPipe,
    NgClass,
    FormsModule
  ],
  templateUrl: './legal-check-box.component.html',
  styleUrl: './legal-check-box.component.scss'
})
export class LegalCheckBoxComponent {

  @Input() inputId!: string;
  @Input() title!: string;
  @Input() downloadLink: string | undefined;
  @Input() ctrl!: FormControl<boolean | null>;
}
