import { BvsBaseBoatInformationFormModel } from '@shared/models/forms/bvs-base-boat-information-form.model';
import { FormControl, Validators } from '@angular/forms';
import { BoatTypeEnum } from '@shared/models/boat-type.enum';
import { BoatConstructionTypeEnum } from '@shared/models/boat-construction-type.enum';
import { MastMaterialEnum } from '@shared/models/mast-material.enum';
import { SailingAreaEnum } from '@shared/models/sailing-area.enum';
import { CountryRegisteredEnum } from '@shared/models/country-registered.enum';

export function createBvsBoatInformationBaseForm(): BvsBaseBoatInformationFormModel {
  return {
    boatType: new FormControl<BoatTypeEnum | null>(null, Validators.required),
    boatConstructionType: new FormControl<BoatConstructionTypeEnum | null>(null),
    engineHp: new FormControl<number | null>(null),
    mastMaterial: new FormControl<MastMaterialEnum | null>(null),

    sailingArea: new FormControl<SailingAreaEnum | null>(null, Validators.required),
    sailArea: new FormControl<number | null>(null),

    countryRegistered: new FormControl<CountryRegisteredEnum | null>(null),
    isCharter: new FormControl<boolean | null>(false),

    maxKnotsOver40: new FormControl<boolean | null>(null),
    boatModel: new FormControl<string | null>(null),
    boatYard: new FormControl<string | null>(null),
    manufacturer: new FormControl<string | null>(null),
    hasDinghy: new FormControl<boolean | null>(null),
    dinghyHp: new FormControl<number | null>(null)
  };
}