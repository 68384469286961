import { AfterViewInit, Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { NgClass, NgOptimizedImage } from '@angular/common';
import { TranslocoPipe } from '@ngneat/transloco';

@Component({
  selector: 'app-callidus-stepper',
  standalone: true,
  imports: [
    NgClass,
    NgOptimizedImage,
    TranslocoPipe
  ],
  templateUrl: './callidus-stepper.component.html',
  styleUrl: './callidus-stepper.component.scss'
})
export class CallidusStepperComponent implements AfterViewInit {

  @Input() stepItems: CalldiusStepItem[] = [];

  @Output() itemClicked = new EventEmitter<CalldiusStepItem>;
  currentPosition!: number;
  isSmallMode = false;
  element: HTMLElement | null = null;

  @Input() set setCurrentPosition(value: number) {
    this.currentPosition = value;
  }

  @HostListener('window:scroll', [])
  onScroll(): void {
    if (this.isMobile()) {
      this.isSmallMode = true;
      return;
    }

    if (window.scrollY > 50) {
      this.isSmallMode = true;

      if (this.element !== null && this.element.style.marginLeft === '') {
        this.stretchStepperWrapper();
      }

      return;
    }

    this.isSmallMode = false;
    this.unStretchStepperWrapper();
  }

  ngAfterViewInit(): void {
    this.element = document.getElementById('stepperWrapper');

    if (this.isMobile()) {
      this.isSmallMode = true;
      this.stretchStepperWrapper();
      return;
    }
  }

  private stretchStepperWrapper(): void {
    const offset = this.element!.getBoundingClientRect().left * -1;
    this.element!.style.marginLeft = offset + 'px';
    this.element!.style.marginRight = offset + 'px';
  }

  private unStretchStepperWrapper(): void {
    this.element!.style.marginLeft = '';
    this.element!.style.marginRight = '';
  }

  private isMobile(): boolean {
    return window.innerWidth < 768;
  }
}

export interface CalldiusStepItem {
  id: string;
  label: string;
  iconUrl: string;
  enabled?: boolean;
}
