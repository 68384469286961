import { Component } from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';

@Component({
  selector: 'app-data-submitted',
  standalone: true,
  imports: [
    TranslocoPipe
  ],
  templateUrl: './data-submitted.component.html',
  styleUrl: './data-submitted.component.scss'
})
export class DataSubmittedComponent {

}
