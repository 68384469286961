import { provideTransloco, Translation, TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { environment } from '@environment/environment';
import { TranslocoHttpLoader } from './transloco.loader';
import { BrokernetLanguageTypeEnum, BrokernetLanguageTypeValues } from '@brokernet/brokernet-ui-lib';
import { firstValueFrom } from 'rxjs';
import { PrimeNGConfig } from 'primeng/api';

/*At this point, we already tried to fetch the language from the Server, see app.module
* There, we set it to the localStorage. So now, if the user was logged in, the language can be set from there
* If there is no language in localStorage, we take the brower's language, but only if supported*/
export function getAppDefaultLanguage(): BrokernetLanguageTypeEnum {
  const url = new URL(window.location.href);

  if (url.searchParams.get('lang') === 'en') {
    localStorage.setItem('app-language', BrokernetLanguageTypeEnum.EN);
    return BrokernetLanguageTypeEnum.EN;
  }

  if (localStorage['app-language']) {
    return localStorage['app-language'];
  }

  /**
   * TODO: find out how to tell the browser inside the testing pipeline to be in german. If its not german,
   * The tests will fail. For now, we just use german as default instead of the browser's language
   * const browserLang = navigator.language.split('-')[0].toUpperCase() as BrokernetLanguageTypeEnum; // Get browser language code
   */
  const browserLang = BrokernetLanguageTypeEnum.DE;
  return BrokernetLanguageTypeValues.getAllEnumKeys().includes(browserLang) ? browserLang : BrokernetLanguageTypeValues.getAllEnumKeys()[0];
}

export function preloadLanguage(transloco: TranslocoHttpLoader,
                                translocoService: TranslocoService,
                                primengConfig: PrimeNGConfig) {
  return function (): Promise<Translation> {
    translocoService.selectTranslateObject('primeng').subscribe((translations) => {
      primengConfig.setTranslation(translations);
    });
    return firstValueFrom(transloco.getTranslation(getAppDefaultLanguage()));
  };
}

@NgModule({
  exports: [TranslocoModule],
  providers: [
    provideTransloco({
      config: {
        availableLangs: BrokernetLanguageTypeValues.getAllEnumKeys(),
        defaultLang: getAppDefaultLanguage(),
        prodMode: environment.production,
        reRenderOnLangChange: true
      },
      loader: TranslocoHttpLoader
    }),
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: preloadLanguage,
      deps: [
        TranslocoHttpLoader,
        TranslocoService,
        PrimeNGConfig
      ]
    }
  ]
})

export class TranslocoRootModule {
}
