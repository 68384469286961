import { AnswerOption } from '@shared/components/question-box/question-box.component';

export const defaultAnswerOptions: AnswerOption<boolean>[] = [
  {
    title: 'shared.yes',
    id: 'true',
    value: true
  },
  {
    title: 'shared.no',
    id: 'false',
    value: false
  }
];