<form
  [formGroup]="form"
  class="container mt-4">
  <div class="row justify-content-center">
    <div class="col-12 col-md-9 col-lg-6">
      <div class="pt-md-3">
        <div class="question-title">
          {{ 'bvs.calculator.additionalInsurance' | transloco }}
        </div>

        <div class="mb-4">
          <app-additional-coverage-box
            [ctrl]="form.controls.bvsBoatInformation.controls.isCharter"
            [icon]="'pi-users'"
            [text]="'bvs.calculator.isCharterText'"
            [title]="'bvs.calculator.isCharterTitle'"></app-additional-coverage-box>
        </div>

        <div class="mb-4">
          <app-additional-coverage-box-for-enum
            [ctrl]="form.controls.figures.controls.bvsNepKunde"
            [icon]="'pi-shield'"
            [switchValues]="[BvsNepKundeEnum.NEP5MIO, BvsNepKundeEnum.NEP10MIO]"
            [text]="'bvs.calculator.bvsNepKundeText'"
            [title]="'bvs.calculator.bvsNepKundeTitle'"></app-additional-coverage-box-for-enum>
        </div>

        <div class="mb-4">
          <app-additional-coverage-box
            [ctrl]="form.controls.figures.controls.isRechtsschutz"
            [icon]="'pi-id-card'"
            [text]="'bvs.calculator.isRechtsschutzText'"
            [title]="'bvs.calculator.isRechtsschutzTitle'"></app-additional-coverage-box>
        </div>

        @if (form.controls.figures.controls.isRechtsschutz.value) {
          <div class="mb-4">
            <app-additional-coverage-box
              [ctrl]="form.controls.figures.controls.isKeinSelbstbehaltBeiRechtsschutz"
              [icon]="'pi-id-card'"
              [text]="'bvs.calculator.isSelbstbehaltText'"
              [title]="'bvs.calculator.isSelbstbehaltTitle'"></app-additional-coverage-box>
          </div>
        }
        <div
          [ngClass]="getPremiumColorCode(this.calculationResult)"
          class="card-body mb-4 box-shadow">

          @if (showInsurancePremium()) {
            <ng-container
              class="mb-5">
              <app-premium-display
                [additionalHint]="form.controls.figures.controls.isRechtsschutz.value === true ? ('bvs.calculator.isRechtsschutzHint'|transloco) : null"
                [premiumValue]="calculationResult?.result!"></app-premium-display>
            </ng-container>
          }
          @if (showErrorMessages()) {
            <app-calculation-error-display
              [result]="calculationResult"></app-calculation-error-display>
          }
        </div>
      </div>
      <div class="mb-5 d-flex justify-content-center">
        <button
          (click)="prev.emit()"
          class="btn btn-sm btn-secondary">
          {{ 'shared.back' | transloco }}
        </button>
        <button
          (click)="next.emit()"
          class="btn btn-sm btn-primary"
          data-cy="continueToCustomerData">
          {{ 'bvs.calculator.continueToCustomerData' | transloco }}
        </button>
      </div>
    </div>
  </div>
</form>
