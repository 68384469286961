<form
  [formGroup]="form"
  class="container">
  <div class="row justify-content-center">
    <div class="col-12 col-md-9 col-lg-6">
      <div class="mb-5">
        <app-question-box
          (answerSelect)="setValueToForm(form.controls.boatType, $event.value)"
          [answers]="boatTypeAnswerOptions"
          [id]="'boat-type'"
          [selectedValue]="form.controls.boatType.value"
          [title]="'bvs.boatType'"></app-question-box>
      </div>
    </div>
  </div>
  <div class="row justify-content-center">
    @if (form.controls.boatType.value !== null) {
      <div class="col-12">
        <div class="mb-5">
          <app-question-box
            [id]="'country'"
            (answerSelect)="setValueToForm(form.controls.country, $event.value)"
            [answers]="countryAnswerOptions"
            [selectedValue]="form.controls.country.value"
            [title]="'bvs.calculator.person.country'"></app-question-box>
        </div>
      </div>
    }
    <div class="col-12 col-md-9 col-lg-6">
      @if (form.controls.country.value !== null) {
        <div
          class="mb-5 row justify-content-center">
          <div
            class="col-sm-4 text-center">
            <label
              [for]="'kautionsBetrag'"
              class="form-label">{{ 'bvs.calculator.kautionsBetrag' | transloco }}</label>

            <p-inputNumber
              [formControl]="form.controls.kautionsBetrag"
              class="input-centered"
              id="kautionsBetrag"
              [inputId]="'kautionsBetrag'"
              locale="de-DE"
              suffix="€"></p-inputNumber>
            <div class="invalid-feedback">
              {{ 'bvs.calculator.charter.max6000'|transloco }}
            </div>
          </div>
        </div>
      }

      @if (form.controls.kautionsBetrag.value !== null && form.controls.kautionsBetrag.valid) {
        <div class="mb-5">
          <app-question-box
            [id]="'charterKautionsfolgeschadenversicherung'"
            (answerSelect)="setValueToForm(form.controls.hasCharterFolgeschadenVersicherung,$event.value)"
            [answers]="defaultAnswerOptions"
            [title]="'bvs.charterKautionsfolgeschadenversicherung'"></app-question-box>
        </div>
      }
      @if (form.controls.hasCharterFolgeschadenVersicherung.value) {
        <div
          class="mb-5 row justify-content-center">
          <div
            class="col-sm-4 mb-3 text-center">
            <label
              [for]="'yachtLength'"
              class="form-label">{{ 'bvs.calculator.yachtLength' | transloco }}</label>

            <p-inputNumber
              [formControl]="form.controls.yachtLength"
              class="input-centered"
              id="yachtLength"
              [inputId]="'yachtLength'"
              locale="de-DE"
              suffix="ft"></p-inputNumber>
            <div class="invalid-feedback">
              {{ 'bvs.calculator.charter.max63ft'|transloco }}
            </div>
          </div>
        </div>
      }

      <div
        [ngClass]="getPremiumColorCode(this.calculationResult)"
        class="card-body mb-4 box-shadow">
        @if (calculationResult !== null && isFormValidForPremiumCalculation()) {
          <div
            class="mb-5">
            <app-premium-display
              [isFixedRate]="true"
              [premiumValue]="calculationResult.result!"></app-premium-display>
          </div>
        }
      </div>
    </div>
    <div class="mb-5 d-flex justify-content-center">
      <button
        (click)="prev.emit()"
        class="btn btn-sm btn-secondary">
        {{ 'shared.back' | transloco }}
      </button>
      <button
        (click)="next.emit()"
        [disabled]="!isFormValidForPremiumCalculation()"
        class="btn btn-sm btn-primary"
        data-cy="continueToCustomerData">
        {{ 'bvs.calculator.continueToCustomerData' | transloco }}
      </button>
    </div>
  </div>
</form>
