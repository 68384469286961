<div
  [ngClass]="{'is-small': isSmallMode}"
  class="callidus-stepper-wrapper position-relative"
  id="stepperWrapper">

  <div
    class="callidus-stepper sticky-top">

    @for (item of stepItems; track $index) {
      <div
        (click)="itemClicked.emit(item)"

        class="stepper-item">

        <div
          [ngClass]="{'is-active': currentPosition === $index, 'is-done': currentPosition > $index }"
          class="step-item-icon">

          @if (currentPosition > $index) {
            <img
              ngSrc="assets/icons/done.svg"
              height="24"
              width="24"
              alt="" />
          } @else {
            <img
              [ngSrc]="item.iconUrl"
              height="24"
              width="24"
              alt="">
          }
        </div>
        <div class="step-item-text">
          {{ item.label | transloco }}
        </div>
      </div>
    }

    <div class="line"></div>
  </div>
</div>
