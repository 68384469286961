<div class="mb-5">
  <div class="col-12 mb-3">
    <h6 class="text-center">{{ 'bvs.calculator.person.legalChecks' | transloco }}</h6>
  </div>

  <div class="row justify-content-center mb-0 mb-md-3">
    <div class="col-12 col-md-9 col-lg-6">
      @for (entry of legalCheckEntries; track $index) {
        <div
          class="mb-3">
          <app-legal-check-box
            [inputId]="entry.id"
            [ctrl]="entry.ctrl"
            [downloadLink]="entry.link"
            [title]="entry.title"></app-legal-check-box>
        </div>
      }
    </div>
  </div>
</div>
