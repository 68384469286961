<form
  [formGroup]="form"
  class="container">

  <div class="row justify-content-center">
    <div class="col-12 mb-3">
      <h6 class="text-center">{{ 'bvs.calculator.person.personDataTitle' | transloco }}</h6>
    </div>

    <div class="col-12">
      <div class="mb-5">
        <app-question-box
          [id]="'gender'"
          (answerSelect)="setValueToForm(form.controls.person.controls.gender, $event.value)"
          [answers]="genderAnswerOptions"
          [selectedValue]="form.controls.person.controls.gender.value"
          [title]="'shared.gender'"></app-question-box>
      </div>
    </div>

    @if (form.controls.person.controls.gender.value !== null) {
      <div class="mb-5">
        <div class="row justify-content-center">
          <div class="col-sm-3 mb-3 text-center">
            <label
              class="form-label"
              for="firstname">
              {{ 'shared.firstName' | transloco }}
            </label>

            <input
              [formControl]="form.controls.person.controls.firstname"
              class="form-control form-control-sm"
              data-cy="firstname"
              id="firstname"
              type="text">
          </div>

          <div class="col-sm-3 mb-3 text-center">
            <label
              class="form-label"
              for="lastName">
              {{ 'shared.lastName' | transloco }}
            </label>

            <input
              [formControl]="form.controls.person.controls.lastname"
              class="form-control form-control-sm"
              data-cy="lastname"
              id="lastName"
              type="text">
          </div>

          <div class="col-sm-3 mb-3 text-center">
            <label
              class="form-label"
              for="birthdate">
              {{ 'shared.birthdate' | transloco }}
            </label>

            <input
              [formControl]="form.controls.person.controls.birthdate"
              class="form-control form-control-sm"
              data-cy="birthdate"
              id="birthdate"
              type="date">
          </div>
        </div>
      </div>
    }
  </div>

  @if (canShowContactData()) {
    <app-contact-data
      [personForm]="form.controls.person"></app-contact-data>
  }

  @if (canShowLegalChecks()) {
    <app-legal-checks-data
      [form]="form.controls.legalChecks"></app-legal-checks-data>
  }

  @if (canShowAdditionalInformation()) {
    @if (form.controls.customerComment) {
      <div class="mb-5">
        <div class="col-12 mb-3">
          <h6 class="text-center">{{ 'bvs.calculator.commentCustomer' | transloco }}</h6>
        </div>

        <div class="row justify-content-center mb-0 mb-md-3">
          <div class="col-12 col-md-9 col-lg-6">
            <textarea
              [formControl]="form .controls.customerComment"
              class="form-control form-control-sm"
              name="customerComment"
              data-cy="customerComment"
              id="customerComment"></textarea>
          </div>
        </div>
      </div>
    }
  }

  <div class="mb-5 d-flex justify-content-center">
    <button
      (click)="prev.emit()"
      class="btn btn-sm btn-secondary">
      {{ 'shared.back' | transloco }}
    </button>

    @if (canSubmit()) {
      <button
        data-cy="sendRequest"
        [isButtonBusy]="sendingRequest"
        appButtonBusy
        (click)="sendCustomerData()"
        class="btn btn-sm btn-primary">
        {{ 'bvs.calculator.sendRequest' | transloco }}
      </button>
    }
  </div>
</form>
