<div
  class="no-sum text-center mb-4">
  @if (result === null) {
    <div
      class="mb-3 error-text pt-4 pb-4">
      <strong> {{ 'bvs.valuesMissing' | transloco }}</strong>
    </div>
  } @else {
    @if (result.calculationResultStatus === CalculationResultStatusEnum.ERROR) {
      <div
        class="error-text pt-4 pb-4">
        <strong>{{ 'bvs.requestOffer' | transloco }}</strong>
      </div>
    }
    @if (result.calculationResultStatus === CalculationResultStatusEnum.ERROR) {
      <div class="mb-1">{{ 'bvs.bvsCalculationAngebotAufAnforderung' | transloco }}:</div>
    }
    @if (result.calculationResultStatus === CalculationResultStatusEnum.OK_WITH_WARNING) {
      <div class="mb-1">{{ 'bvs.bvsCalculationOkMitHinweisen' | transloco }}:</div>
    }
    @if (result.calculationResultStatusDetails.length) {
      <ul class="list-unstyled pb-4">
        @for (statusDetails of result.calculationResultStatusDetails; track $index) {
          <li>
            {{ $index + 1 }}. {{ 'bvs.' + statusDetails | transloco }}
          </li>
        }
      </ul>
    }
  }
</div>
