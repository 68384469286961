<section class="container-fluid position-relative">
  <div class="container sticky-top">
    <div class="row justify-content-center">
      <div class="col-md-9 mb-4">
        <app-callidus-stepper
          [setCurrentPosition]="getCurrentPosition()"
          [stepItems]="stepItems"></app-callidus-stepper>
      </div>
    </div>
  </div>

  @switch (curentNavigationStep) {
    @case (HaftpflichtKaskoNavigationEnum.Base) {
      <app-boat-data-haftpflicht-kasko
        (next)="moveToNextStep()"
        (prev)="moveToPreviousStep()"
        [form]="form"></app-boat-data-haftpflicht-kasko>
    }
    @case (HaftpflichtKaskoNavigationEnum.Pricing) {
      <app-pricing-data
        (next)="moveToNextStep()"
        (prev)="moveToPreviousStep()"
        [form]="form"></app-pricing-data>
    }
    @case (HaftpflichtKaskoNavigationEnum.AdditionalCoverage) {
      <app-additional-coverage-haftpflicht-kasko-data
        (next)="moveToNextStep()"
        (prev)="moveToPreviousStep()"
        [form]="form"></app-additional-coverage-haftpflicht-kasko-data>
    }
    @case (HaftpflichtKaskoNavigationEnum.Customer) {
      <app-customer-data
        (prev)="moveToPreviousStep()"
        (submitted)="submitData()"
        [form]="form.controls.customerData"></app-customer-data>
    }
    @case (HaftpflichtKaskoNavigationEnum.Done) {
      <app-data-submitted></app-data-submitted>
    }
  }
</section>
