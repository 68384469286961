import { Component, Input } from '@angular/core';
import { PaginatorModule } from 'primeng/paginator';
import { TranslocoPipe } from '@ngneat/transloco';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { PersonFormModel } from '@shared/models/forms/person-form.model';

@Component({
  selector: 'app-contact-data',
  standalone: true,
  imports: [
    PaginatorModule,
    TranslocoPipe,
    ReactiveFormsModule
  ],
  templateUrl: './contact-data.component.html',
  styleUrl: './contact-data.component.scss'
})
export class ContactDataComponent {

  @Input() personForm!: FormGroup<PersonFormModel>;

}
