import { Directive, inject } from '@angular/core';
import { CallidusCurrencyPipe } from '@shared/pipes/currency/callidus-currency.pipe';
import { CalculationResultModel } from '@shared/models/calculation-result.model';
import { BvsNepKundeEnum } from '@shared/models/bvs-nep-kunde.enum';
import { DataBaseComponent } from '@shared/directives/data-base.component';

@Directive()
export class AdditionalCoverageBaseComponent extends DataBaseComponent {
  currencyPipe = inject(CallidusCurrencyPipe);

  calculationResult: CalculationResultModel | null = null;

  protected readonly BvsNepKundeEnum = BvsNepKundeEnum;
}
