import { Component, inject, Input, OnInit } from '@angular/core';
import { InputNumberModule } from 'primeng/inputnumber';
// eslint-disable-next-line no-restricted-imports
import { NgClass, NgForOf } from '@angular/common';
import { PaginatorModule } from 'primeng/paginator';
import { QuestionBoxComponent } from '@shared/components/question-box/question-box.component';
import { TranslocoPipe } from '@ngneat/transloco';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';

import { CalculationResultStatusEnum } from '@shared/models/calculation-result-status.enum';

import { takeUntil } from 'rxjs';
import { IbanFormatPipeModule, isFormGroupInvalidExcept } from '@brokernet/brokernet-ui-lib';

import { CallidusCurrencyPipe } from '@shared/pipes/currency/callidus-currency.pipe';
import { PremiumDisplayComponent } from '@shared/components/premium-display/premium-display.component';
import { AdditionalCoverageBoxComponent } from '@shared/components/additional-coverage-box/additional-coverage-box.component';
import { CalculationErrorDisplayComponent } from '@shared/components/calculation-error-display/calculation-error-display.component';
import { BvsHaftpflichtFormModel } from '../../models/form/bvs-haftpflicht-form.model';
import { CalculationResultModel } from '@shared/models/calculation-result.model';
import { BvsHaftpflichtApiService } from '../../services/bvs-haftpflicht-api.service';
import { AdditionalCoverageBaseComponent } from '@shared/directives/additional-coverage-base-component.directive';
import { GetBvsVersicherungsPraemieCommandModel } from '@shared/models/commands/get-versicherungs-praemie-charter-kautionsversicherung-command.model';
import { AdditionalCoverageBoxForEnumComponent } from '@shared/components/additional-coverage-for-enum/additional-coverage-box-for-enum.component';

@Component({
  selector: 'app-additional-coverage-haftpflicht-data',
  standalone: true,
  imports: [
    InputNumberModule,
    PaginatorModule,
    QuestionBoxComponent,
    TranslocoPipe,
    ReactiveFormsModule,
    PremiumDisplayComponent,
    AdditionalCoverageBoxComponent,
    CalculationErrorDisplayComponent,
    NgClass,
    IbanFormatPipeModule,
    NgForOf,
    AdditionalCoverageBoxForEnumComponent
  ],
  providers: [CallidusCurrencyPipe, BvsHaftpflichtApiService],
  templateUrl: './additional-coverage-haftpflicht-data.component.html',
  styleUrl: './additional-coverage-haftpflicht-data.component.scss'
})
export class AdditionalCoverageHaftpflichtDataComponent extends AdditionalCoverageBaseComponent implements OnInit {
  bvsHaftpflichtApiService = inject(BvsHaftpflichtApiService);

  @Input() form!: FormGroup<BvsHaftpflichtFormModel>;

  ngOnInit(): void {
    this.calculate();

    this
      .form
      .valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => this.calculate());
  }

  calculate(): void {
    if (this.isFormInvalidForPremiumCalculation()) {
      return;
    }
    const getBvsVersicherungsPraemieCommand = this.createGetBvsVersicherungsPraemieCommand();
    this.bvsHaftpflichtApiService
      .calculate(getBvsVersicherungsPraemieCommand)
      .subscribe((calculationResult: CalculationResultModel) => this.calculationResult = calculationResult);
  }

  showInsurancePremium(): boolean {
    return !this.isFormInvalidForPremiumCalculation() &&
      this.calculationResult !== null &&
      (this.calculationResult.calculationResultStatus === CalculationResultStatusEnum.OK ||
        this.calculationResult.calculationResultStatus === CalculationResultStatusEnum.OK_WITH_WARNING);
  }

  showErrorMessages(): boolean {
    return this.isFormInvalidForPremiumCalculation() ||
      (this.calculationResult !== null &&
        (this.calculationResult.calculationResultStatus === CalculationResultStatusEnum.OK_WITH_WARNING ||
          this.calculationResult.calculationResultStatus === CalculationResultStatusEnum.ERROR));

  }

  isFormInvalidForPremiumCalculation(): boolean {
    return isFormGroupInvalidExcept(this.form, ['customerData']);
  }

  createGetBvsVersicherungsPraemieCommand(): GetBvsVersicherungsPraemieCommandModel {
    return {
      ...this.form.controls.bvsBoatInformation.getRawValue(),
      ...this.form.controls.figures.getRawValue(),
      totalInsuranceSum: 0,
      isMaschinendeckung: null,
      boatConstructionType: null,
      selbstbehaltKunde: null,
      constructionYear: null,
      isSport: null,
      hullMaterial: null
    };
  }
}


