import { CountryRegisteredEnum, CountryRegisteredValues } from '@shared/models/country-registered.enum';
import { AnswerOption } from '@shared/components/question-box/question-box.component';
import { CountryEnum, CountryEnumValues } from '@brokernet/brokernet-ui-lib';

/*
*Options for CountryRegistered, used by BVS
 */
export const countryRegisteredAnswerOptions: AnswerOption<CountryRegisteredEnum> [] = [
  {
    title: CountryRegisteredValues.getEnumByKey(CountryRegisteredEnum.AUT)?.label!,
    id: CountryRegisteredEnum.AUT,
    value: CountryRegisteredEnum.AUT
  },
  {
    title: CountryRegisteredValues.getEnumByKey(CountryRegisteredEnum.GER)?.label!,
    id: CountryRegisteredEnum.GER,
    value: CountryRegisteredEnum.GER
  },
  {
    title: CountryRegisteredValues.getEnumByKey(CountryRegisteredEnum.CRO)?.label!,
    id: CountryRegisteredEnum.CRO,
    value: CountryRegisteredEnum.CRO
  },
  {
    title: CountryRegisteredValues.getEnumByKey(CountryRegisteredEnum.SLO)?.label!,
    id: CountryRegisteredEnum.SLO,
    value: CountryRegisteredEnum.SLO
  }
];

/*
*Options for Country, used in the person adress
 */
export const countryAnswerOptions: AnswerOption<CountryEnum> [] = [
  {
    title: CountryEnumValues.getEnumByKey(CountryEnum.AUSTRIA)?.label!,
    id: CountryEnum.AUSTRIA,
    value: CountryEnum.AUSTRIA
  },
  {
    title: CountryEnumValues.getEnumByKey(CountryEnum.GERMANY)?.label!,
    id: CountryEnum.GERMANY,
    value: CountryEnum.GERMANY
  },
  {
    title: CountryEnumValues.getEnumByKey(CountryEnum.CROATIA)?.label!,
    id: CountryEnum.CROATIA,
    value: CountryEnum.CROATIA
  },
  {
    title: CountryEnumValues.getEnumByKey(CountryEnum.SLOVENIA)?.label!,
    id: CountryEnum.SLOVENIA,
    value: CountryEnum.SLOVENIA
  }
];