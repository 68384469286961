import { FormControl, FormGroup, Validators } from '@angular/forms';

import { SkipperHaftpflichtFormModel } from '../models/forms/skipper-haftpflicht-form.model';
import { SkipperVersicherungssummeKundeEnum } from '@shared/models/skipper-versicherungssumme-kunde.enum';

export function createSkipperHaftpflichtForm(): FormGroup<SkipperHaftpflichtFormModel> {
  return new FormGroup<SkipperHaftpflichtFormModel>({
    skipperVersicherungssummeKunde: new FormControl<SkipperVersicherungssummeKundeEnum>(SkipperVersicherungssummeKundeEnum.VS5MIO, Validators.required),

    hasBeantragteRisikenGekuendigtOderAbgelehnt: new FormControl<boolean | null>(null, Validators.required),
    versicherungsgesellschaftGekuendigtOderAbgelehnt: new FormControl<string | null>(null),
    yearGekuendigtOderAbgelehnt: new FormControl<number | null>(null),
    policyNumberGekuendigtOderAbgelehnt: new FormControl<string | null>(null),
    sparteGekuendigtOderAbgelehnt: new FormControl<string | null>(null),
    grundGekuendigtOderAbgelehnt: new FormControl<string | null>(null),

    hasSchaedenBereitsErlitten: new FormControl<boolean | null>(null, Validators.required),
    hasRisikenBereitsVersichert: new FormControl<boolean | null>(null, Validators.required)
  });
}
