import { Component, inject, Input, OnInit } from '@angular/core';
import { InputNumberModule } from 'primeng/inputnumber';
// eslint-disable-next-line no-restricted-imports
import { NgClass, NgForOf } from '@angular/common';
import { PaginatorModule } from 'primeng/paginator';
import { QuestionBoxComponent } from '@shared/components/question-box/question-box.component';
import { TranslocoPipe } from '@ngneat/transloco';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CalculationResultStatusEnum } from '@shared/models/calculation-result-status.enum';
import { takeUntil } from 'rxjs';
import { BoatTypeEnum } from '@shared/models/boat-type.enum';
import { IbanFormatPipeModule, isFormGroupInvalidExcept } from '@brokernet/brokernet-ui-lib';
import { SelbstbehaltKundeEnum } from '@shared/models/selbstbehalt-kunde.enum';
import { CallidusCurrencyPipe } from '@shared/pipes/currency/callidus-currency.pipe';
import { AdditionalCoverageBoxComponent } from '@shared/components/additional-coverage-box/additional-coverage-box.component';
import { SelectOption } from '@shared/models/select-option.model';
import { BvsHaftpflichtUndKaskoFormModel } from '../../models/form/bvs-haftpflicht-und-kasko-form.model';
import { PremiumDisplayComponent } from '@shared/components/premium-display/premium-display.component';
import { CalculationErrorDisplayComponent } from '@shared/components/calculation-error-display/calculation-error-display.component';
import { BvsHaftpflichtKaskoApiService } from '../../services/bvs-haftpflicht-kasko-api.service';
import { CalculationResultModel } from '@shared/models/calculation-result.model';
import { AdditionalCoverageBaseComponent } from '@shared/directives/additional-coverage-base-component.directive';
import { GetBvsVersicherungsPraemieCommandModel } from '@shared/models/commands/get-versicherungs-praemie-charter-kautionsversicherung-command.model';
import { AdditionalCoverageBoxForEnumComponent } from '@shared/components/additional-coverage-for-enum/additional-coverage-box-for-enum.component';

@Component({
  selector: 'app-additional-coverage-haftpflicht-kasko-data',
  standalone: true,
  imports: [
    InputNumberModule,
    PaginatorModule,
    QuestionBoxComponent,
    TranslocoPipe,
    ReactiveFormsModule,
    PremiumDisplayComponent,
    AdditionalCoverageBoxComponent,
    CalculationErrorDisplayComponent,
    NgClass,
    IbanFormatPipeModule,
    NgForOf,
    AdditionalCoverageBoxForEnumComponent
  ],
  providers: [CallidusCurrencyPipe, BvsHaftpflichtKaskoApiService],
  templateUrl: './additional-coverage-haftpflicht-kasko-data.component.html',
  styleUrl: './additional-coverage-haftpflicht-kasko-data.component.scss'
})
export class AdditionalCoverageHaftpflichtKaskoDataComponent extends AdditionalCoverageBaseComponent implements OnInit {
  bvsHaftpflichtKaskoApiService = inject(BvsHaftpflichtKaskoApiService);

  @Input() form!: FormGroup<BvsHaftpflichtUndKaskoFormModel>;

  filteredSelectOptions: SelectOption<SelbstbehaltKundeEnum>[] = [];

  selectOptions: SelectOption<SelbstbehaltKundeEnum>[] = [
    { value: SelbstbehaltKundeEnum.SB500, display: this.currencyPipe.transform(500, 'symbol') },
    { value: SelbstbehaltKundeEnum.SB1000, display: this.currencyPipe.transform(1000, 'symbol') },
    { value: SelbstbehaltKundeEnum.SB2500, display: this.currencyPipe.transform(2500, 'symbol') },
    { value: SelbstbehaltKundeEnum.SB5000, display: this.currencyPipe.transform(5000, 'symbol') },
    { value: SelbstbehaltKundeEnum.SB7500, display: this.currencyPipe.transform(7500, 'symbol') },
    { value: SelbstbehaltKundeEnum.SB10000, display: this.currencyPipe.transform(10000, 'symbol') }
  ];

  ngOnInit(): void {
    this.calculate();

    this
      .form
      .valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => this.calculate());

    this.getMoeglicheSelbstbehalte();
  }

  calculate(): void {
    if (this.isFormInvalidForPremiumCalculation()) {
      return;
    }
    const getBvsVersicherungsPraemieCommand = this.createGetBvsVersicherungsPraemieCommand();

    this.bvsHaftpflichtKaskoApiService
      .calculate(getBvsVersicherungsPraemieCommand)
      .subscribe((calculationResult: CalculationResultModel) => this.calculationResult = calculationResult);
  }

  showInsurancePremium(): boolean {
    return !this.isFormInvalidForPremiumCalculation() &&
      this.calculationResult !== null &&
      (this.calculationResult.calculationResultStatus === CalculationResultStatusEnum.OK ||
        this.calculationResult.calculationResultStatus === CalculationResultStatusEnum.OK_WITH_WARNING);
  }

  showErrorMessages(): boolean {
    return this.isFormInvalidForPremiumCalculation() ||
      (this.calculationResult !== null &&
        (this.calculationResult.calculationResultStatus === CalculationResultStatusEnum.OK_WITH_WARNING ||
          this.calculationResult.calculationResultStatus === CalculationResultStatusEnum.ERROR));

  }

  isBoatLessThan10YearsOld(): boolean {
    return !!this.form.controls.bvsBoatInformation.controls.constructionYear.value
      && (new Date().getFullYear() - this.form.controls.bvsBoatInformation.controls.constructionYear.value < 10);
  }

  isSailingBoat(): boolean {
    return this.form.controls.bvsBoatInformation.value.boatType === BoatTypeEnum.SEGELBOOT_SEGELYACHT;
  }

  isFormInvalidForPremiumCalculation(): boolean {
    return isFormGroupInvalidExcept(this.form, ['customerData']);
  }

  createGetBvsVersicherungsPraemieCommand(): GetBvsVersicherungsPraemieCommandModel {
    return {
      ...this.form.controls.bvsBoatInformation.getRawValue(),
      ...this.form.controls.bvsFigures.getRawValue(),
      totalInsuranceSum: this.form.controls.bvsFigures.controls.totalInsuranceSum.value ? this.form.controls.bvsFigures.controls.totalInsuranceSum.value : 0
    };
  }

  getMoeglicheSelbstbehalte(): void {
    const getBvsVersicherungsPraemieCommand = this.createGetBvsVersicherungsPraemieCommand();
    this.bvsHaftpflichtKaskoApiService
      .getMoeglicheSelbstbehalte(getBvsVersicherungsPraemieCommand)
      .subscribe(selbstbehalte => this.handleGetMoegelicheSelbstbehalte(selbstbehalte));
  }

  handleGetMoegelicheSelbstbehalte(sbs: SelbstbehaltKundeEnum[]): void {
    this.filteredSelectOptions = this.selectOptions.filter(option => sbs.includes(option.value));

    if (this.filteredSelectOptions.length) {
      this.form.controls.bvsFigures.controls.selbstbehaltKunde.enable();
    } else {
      this.form.controls.bvsFigures.controls.selbstbehaltKunde.disable();
    }
  }
}


