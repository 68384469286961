<form
  [formGroup]="form"
  class="container">
  <div class="row justify-content-center">
    <div class="col-12 col-md-9 col-lg-6">


      <div class="mb-4">
        <app-question-box
          (answerSelect)="setValueToForm(form.controls.skipperRechtsschutz.controls.isSailAreaExplicitlyInEurope, $event.value)"
          [answers]="defaultAnswerOptions"
          [id]="'isSailAreaExplicitlyInEurope'"
          [selectedValue]="form.controls.skipperRechtsschutz.controls.isSailAreaExplicitlyInEurope.value"
          [title]="'bvs.calculator.skipper.isSailAreaExplicitlyEuropeTitle'"></app-question-box>
        @if (form.controls.skipperRechtsschutz.controls.isSailAreaExplicitlyInEurope.value === false) {
          <div
            class="alert alert-warning mx-1 text-center d-flex align-items-center"
            role="alert">
            <i class="pi pi-info-circle col-auto me-3 flex-grow-0 font-big">
            </i>
            <div class="flex-grow-1">
              {{ 'bvs.calculator.skipper.isSailAreaExplicitlyEuropeText'|transloco }}
            </div>
          </div>
        }
      </div>

      @if (!!form.controls.skipperRechtsschutz.controls.isSailAreaExplicitlyInEurope.value) {
        <div class="mb-4">
          <app-question-box
            (answerSelect)="setValueToForm(form.controls.skipperRechtsschutz.controls.isBoatExplicitlyUsedPrivately, $event.value)"
            [answers]="defaultAnswerOptions"
            [id]="'isBoatExplicitlyUsedPrivately'"
            [selectedValue]="form.controls.skipperRechtsschutz.controls.isBoatExplicitlyUsedPrivately.value"
            [title]="'bvs.calculator.skipper.isBoatExplicitlyUsedPrivatelyTitle'"></app-question-box>
        </div>
        @if (form.controls.skipperRechtsschutz.controls.isBoatExplicitlyUsedPrivately.value === false) {
          <div
            class="alert alert-warning mx-1 text-center d-flex align-items-center"
            role="alert">
            <i class="pi pi-info-circle col-auto me-3 flex-grow-0 font-big">
            </i>
            <div class="flex-grow-1">
              {{ 'bvs.calculator.skipper.isBoatExplicitlyUsedPrivatelyText'|transloco }}
            </div>
          </div>
        }
      }

      @if (!!form.controls.skipperRechtsschutz.controls.isBoatExplicitlyUsedPrivately.value) {
        <div class="mb-4">
          <app-question-box
            (answerSelect)="setValueToForm(form.controls.skipperRechtsschutz.controls.hasAlreadyHadLegalProtectionInsurance, $event.value)"
            [answers]="defaultAnswerOptions"
            [id]="'hasAlreadyHadLegalProtectionInsurance'"
            [selectedValue]="form.controls.skipperRechtsschutz.controls.hasAlreadyHadLegalProtectionInsurance.value"
            [title]="'bvs.calculator.skipper.hasAlreadyHadLegalProtectionInsurance'"></app-question-box>
        </div>

        <div class="mb-4">
          <app-question-box
            (answerSelect)="setValueToForm(form.controls.skipperRechtsschutz.controls.hasAlreadyHadDamagesOrLegalDispute, $event.value)"
            [answers]="defaultAnswerOptions"
            [id]="'hasAlreadyHadDamagesOrLegalDispute'"
            [selectedValue]="form.controls.skipperRechtsschutz.controls.hasAlreadyHadDamagesOrLegalDispute.value"
            [title]="'bvs.calculator.skipper.hasAlreadyHadDamagesOrLegalDisputeTitle'"></app-question-box>
        </div>

        @if (form.controls.skipperRechtsschutz.controls.hasAlreadyHadDamagesOrLegalDispute.value) {
          <div class="mb-4">
            <label
              class="form-label"
              for="hasAlreadyHadDamagesOrLegalDisputeDescription">
              {{ 'bvs.calculator.skipper.hasAlreadyHadDamagesOrLegalDisputeSubTitle' | transloco }}
            </label>

            <textarea
              [formControl]="form.controls.skipperRechtsschutz.controls.hasAlreadyHadDamagesOrLegalDisputeDescription"
              class=" form-control form-control-sm"
              id="hasAlreadyHadDamagesOrLegalDisputeDescription"
              data-cy="hasAlreadyHadDamagesOrLegalDisputeDescription">
          </textarea>
          </div>
        }
      }
      <div
        [ngClass]="getPremiumColorCode(skipperCalculationService.calculationResult)"
        class="card-body mb-4 box-shadow">

        <div
          class="mb-5">
          <app-premium-display
            [premiumValue]="skipperCalculationService.calculationResult?.result!"></app-premium-display>
          @if (skipperCalculationService.showErrorMessages()) {
            <app-calculation-error-display
              [result]="skipperCalculationService.calculationResult"></app-calculation-error-display>
          }
        </div>
      </div>
      <div class="mb-5 mt-5 d-flex justify-content-center">
        <button
          (click)="prev.emit()"
          class="btn btn-sm btn-secondary">
          {{ 'shared.back' | transloco }}
        </button>
        @if (isValid()) {
          <button
            (click)="next.emit()"
            class="btn btn-sm btn-primary"
            data-cy="continueToCustomerData">
            {{ 'bvs.calculator.continueToCustomerData' | transloco }}
          </button>
        }
      </div>
    </div>
  </div>
</form>
