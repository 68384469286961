<div class="pt-md-3">
  <div class="mb-4">
    <app-additional-coverage-box
      [ctrl]="form.controls.hasRechtsschutz"
      [customSwitchValues]="[false, true]"
      [icon]="'pi-id-card'"
      [id]="'hasRechtsschutz'"
      [text]="'bvs.calculator.hasRechtsschutzText'"
      [title]="'bvs.calculator.hasRechtsschutzTitle'"></app-additional-coverage-box>
  </div>
  @if (!!form.controls.hasRechtsschutz.value) {
    <div class="mb-4">
      <app-additional-coverage-box
        [ctrl]="form.controls.skipperRechtsschutz.controls.hasSelbstbehalt"
        [icon]="'pi-shield'"
        [id]="'hasSelbstbehalt'"
        [text]="'bvs.calculator.skipper.hasSelbstbehaltText'"
        [title]="'bvs.calculator.skipper.hasSelbstbehaltTitle'"></app-additional-coverage-box>
    </div>

    <div class="mb-4">
      <app-additional-coverage-box
        [ctrl]="form.controls.skipperRechtsschutz.controls.hasCharterVertragsRechtsschutz"
        [icon]="'pi-money-bill'"
        [id]="'hasCharterVertragsRechtsschutz'"
        [text]="'bvs.calculator.hasCharterVertragsRechtsschutzText'"
        [title]="'bvs.calculator.hasCharterVertragsRechtsschutzTitle'"></app-additional-coverage-box>
    </div>
  }
</div>