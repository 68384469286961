import { Component, Input, OnInit } from '@angular/core';
import { QuestionBoxComponent } from '@shared/components/question-box/question-box.component';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { PricingDataBoxComponent } from '../pricing-data-box/pricing-data-box.component';
import { CurrencyPipe } from '@angular/common';
import { merge, takeUntil } from 'rxjs';
import { TranslocoPipe } from '@ngneat/transloco';
import { InputNumberModule } from 'primeng/inputnumber';
import { BvsHaftpflichtUndKaskoFormModel } from '../../models/form/bvs-haftpflicht-und-kasko-form.model';
import { DataBaseComponent } from '@shared/directives/data-base.component';

@Component({
  selector: 'app-pricing-data',
  standalone: true,
  imports: [
    QuestionBoxComponent,
    ReactiveFormsModule,
    PricingDataBoxComponent,
    TranslocoPipe,
    CurrencyPipe,
    InputNumberModule
  ],
  templateUrl: './pricing-data.component.html',
  styleUrl: './pricing-data.component.scss'
})
export class PricingDataComponent extends DataBaseComponent implements OnInit {
  @Input() form!: FormGroup<BvsHaftpflichtUndKaskoFormModel>;

  ngOnInit(): void {
    const figures = this.form.controls.bvsFigures;

    if (figures.controls.totalInsuranceSum.value === null) {
      figures.controls.totalInsuranceSum.setValue(0);
    }

    this.calculateSum();

    merge(
      figures.controls.insuranceSumBoat.valueChanges,
      figures.controls.insuranceSumAdditionalEquipment.valueChanges,
      figures.controls.insuranceSumPersoenlicheEffekte.valueChanges,
      figures.controls.insuranceSumDinghy.valueChanges,
      figures.controls.insuranceSumTrailer.valueChanges
    )
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => this.calculateSum());
  }

  calculateSum(): void {
    const figures = this.form.controls.bvsFigures;
    let totalInsuranceSum = 0;

    if (figures.controls.insuranceSumBoat?.value !== null) {
      totalInsuranceSum += figures.controls.insuranceSumBoat.value;
    }

    if (figures.controls.insuranceSumAdditionalEquipment?.value !== null) {
      totalInsuranceSum += figures.controls.insuranceSumAdditionalEquipment.value;
    }

    const effectCtrl = figures.controls.insuranceSumPersoenlicheEffekte;
    if (effectCtrl?.value !== null && effectCtrl.value > 1500) {
      /**
       * 1.500 € sind prämienfrei versichert bei den persönlichen Effekten, daher ziehen wir 1500 ab, bei Werten über 1500
       */
        totalInsuranceSum += (effectCtrl.value - 1500);
    }

    if (figures.controls.insuranceSumDinghy?.value !== null) {
      totalInsuranceSum += figures.controls.insuranceSumDinghy.value;
    }

    if (figures.controls.insuranceSumTrailer?.value !== null) {
      totalInsuranceSum += figures.controls.insuranceSumTrailer.value;
    }

    figures.controls.totalInsuranceSum.setValue(totalInsuranceSum);
  }

  nextStep(): void {
    const figures = this.form.controls.bvsFigures;

    if (!figures.controls.insuranceSumBoat?.value) {
      figures.controls.insuranceSumBoat.setValue(0);
    }

    if (!figures.controls.insuranceSumAdditionalEquipment?.value) {
      figures.controls.insuranceSumAdditionalEquipment.setValue(0);
    }

    if (!figures.controls.insuranceSumDinghy?.value) {
      figures.controls.insuranceSumDinghy.setValue(0);
    }

    if (!figures.controls.insuranceSumTrailer?.value) {
      figures.controls.insuranceSumTrailer.setValue(0);
    }

    this.next.emit();
  }
}
