import { Directive } from '@angular/core';
import { DataBaseComponent } from '@shared/directives/data-base.component';
import { AnswerOption } from '@shared/components/question-box/question-box.component';
import { MastMaterialEnumValues } from '@shared/models/mast-material.enum';
import { BoatConstructionTypeEnum, BoatConstructionTypeEnumValues } from '@shared/models/boat-construction-type.enum';
import { BoatTypeEnum } from '@shared/models/boat-type.enum';

@Directive()
export class BoatDataBaseComponent extends DataBaseComponent {
  boatTypeAnswerOptions: AnswerOption<BoatTypeEnum>[] = [
    { title: 'bvs.motoryachtMotorboot', id: BoatTypeEnum.MOTORBOOT_MOTORYACHT, value: BoatTypeEnum.MOTORBOOT_MOTORYACHT },
    { title: 'bvs.segelyachtSegelboot', id: BoatTypeEnum.SEGELBOOT_SEGELYACHT, value: BoatTypeEnum.SEGELBOOT_SEGELYACHT }
  ];

  mastMaterialValues = MastMaterialEnumValues.getLabelKeys();

  boatConstructionTypeOptions: AnswerOption<BoatConstructionTypeEnum>[] = [
    {
      title: BoatConstructionTypeEnumValues.getEnumByKey(BoatConstructionTypeEnum.GLEITER)?.label!,
      id: BoatConstructionTypeEnum.GLEITER,
      value: BoatConstructionTypeEnum.GLEITER
    },
    {
      title: BoatConstructionTypeEnumValues.getEnumByKey(BoatConstructionTypeEnum.VERDRAENGER)?.label!,
      id: BoatConstructionTypeEnum.VERDRAENGER,
      value: BoatConstructionTypeEnum.VERDRAENGER
    }
  ];
  protected readonly BoatTypeEnum = BoatTypeEnum;
}
