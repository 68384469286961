<form
  [formGroup]="form"
  class="container">
  <div class="col-12">
    <div class="mb-5">
      <div
        class="mb-5">
        <app-question-box
          (answerSelect)="setValueToForm(form.controls.bvsBoatInformation.controls.boatType, $event.value)"
          [answers]="boatTypeAnswerOptions"
          [id]="'boat-type'"
          [selectedValue]="form.controls.bvsBoatInformation.controls.boatType.value"
          [title]="'bvs.boatType'"></app-question-box>
      </div>
    </div>
  </div>

  <div class="row justify-content-center">
    <div class="col-12">
      @if (form.controls.bvsBoatInformation.controls.boatType.value !== null) {
        <div class="mb-5">
          <app-question-box
            [id]="'country'"
            (answerSelect)="setValueToForm(form.controls.bvsBoatInformation.controls.countryRegistered, $event.value)"
            [answers]="countryRegisteredAnswerOptions"
            [selectedValue]="form.controls.bvsBoatInformation.controls.countryRegistered.value"
            [title]="'bvs.calculator.countryRegistered'"></app-question-box>
        </div>
      }

      @if (form.controls.bvsBoatInformation.controls.countryRegistered.value !== null) {
        <div class="row justify-content-center">
          @if (form.controls.bvsBoatInformation.controls.boatType.value === BoatTypeEnum.MOTORBOOT_MOTORYACHT) {
            <div class="mb-5">
              <app-question-box
                [id]="'max-knots-over-40'"
                (answerSelect)="setValueToForm(form.controls.bvsBoatInformation.controls.maxKnotsOver40, $event.value)"
                [answers]="defaultAnswerOptions"
                [selectedValue]="form.controls.bvsBoatInformation.controls.maxKnotsOver40.value"
                [title]="'bvs.maxKnotsOver40'"></app-question-box>
            </div>
          }
        </div>
      }

      @if (canShowConstructionType()) {
        <div
          class="mb-5">
          <app-question-box
            [id]="'sail-and-engine'"
            (answerSelect)="setValueToForm(form.controls.bvsBoatInformation.controls.boatConstructionType, $event.value)"
            [answers]="boatConstructionTypeOptions"
            [selectedValue]="form.controls.bvsBoatInformation.controls.boatConstructionType.value"
            [title]="'bvs.calculator.sailAndEngineTitle'"></app-question-box>
        </div>
      }

      @if (canShowHasDinghy()) {
        <div
          class="mb-5">
          <app-question-box
            [id]="'has-dinghy'"
            (answerSelect)="setValueToForm(form.controls.bvsBoatInformation.controls.hasDinghy, $event.value)"
            [answers]="defaultAnswerOptions"
            [selectedValue]="form.controls.bvsBoatInformation.controls.hasDinghy.value"
            [title]="'bvs.calculator.hasDinghy'"></app-question-box>
        </div>
      }

      @if (canShowBoatMaterialDetails()) {
        <div class="row mb-4 justify-content-center">
          @if (form.controls.bvsBoatInformation.controls.boatType.value === BoatTypeEnum.SEGELBOOT_SEGELYACHT) {
            <div class="col-sm-4 mb-3 text-center">
              <label
                [for]="'mastMaterial'"
                class="form-label">{{ 'bvs.mastMaterial' | transloco }}</label>

              <select
                [formControl]="form.controls.bvsBoatInformation.controls.mastMaterial"
                [id]="'mastMaterial'"
                data-cy="mastMaterial"
                class="form-select form-select-sm">

                @for (option of mastMaterialValues; track $index) {
                  <option
                    [ngValue]="option.key">
                    {{ option.label | transloco }}
                  </option>
                }
              </select>
            </div>
          }

          @if (form.controls.bvsBoatInformation.controls.boatType.value === BoatTypeEnum.MOTORBOOT_MOTORYACHT) {
            <div
              class="col-sm-4 mb-3 text-center">
              <label
                [for]="'engineHp'"
                class="form-label">{{ 'bvs.calculator.engineHp' | transloco }}</label>

              <p-inputNumber
                [formControl]="form.controls.bvsBoatInformation.controls.engineHp"
                class="input-centered"
                id="engineHp"
                [inputId]="'engineHp'"
                locale="de-DE"
                suffix=" PS"></p-inputNumber>
              @if (!!form.controls.bvsBoatInformation.controls.hasDinghy.value) {
                <div class="text-muted">*{{ 'bvs.calculator.engineHpSubText'| transloco }}</div>
              }
            </div>
          }

          @if (!!form.controls.bvsBoatInformation.controls.hasDinghy.value) {
            <div
              class="col-sm-4 mb-3 text-center">
              <label
                [for]="'dinghyHp'"
                class="form-label">{{ 'bvs.calculator.dinghyHp' | transloco }}</label>

              <p-inputNumber
                [formControl]="form.controls.bvsBoatInformation.controls.dinghyHp"
                class="input-centered"
                id="dinghyHp"
                [inputId]="'dinghyHp'"
                locale="de-DE"
                suffix=" PS"></p-inputNumber>
            </div>
          }

          @if (form.controls.bvsBoatInformation.controls.boatType.value === BoatTypeEnum.SEGELBOOT_SEGELYACHT) {
            <div
              class="col-sm-4 mb-3 text-center">
              <label
                [for]="'sailArea'"
                class="form-label">{{ 'bvs.calculator.sailArea' | transloco }}</label>

              <p-inputNumber
                [formControl]="form.controls.bvsBoatInformation.controls.sailArea"
                class="input-centered"
                id="sailArea"
                [inputId]="'sailArea'"
                locale="de-DE"
                suffix="m²"></p-inputNumber>
            </div>
          }
        </div>

        @if (canShowYardForSailBoat() || canShowYardForMotorboat()) {
          <div class="row mb-4 justify-content-center">
            <div
              class="col-sm-4 mb-3 text-center">
              <label
                [for]="'boatYard'"
                class="form-label">{{ 'bvs.calculator.boatYard' | transloco }}</label>

              <input
                [formControl]="form.controls.bvsBoatInformation.controls.boatYard"
                class="form-control form-control-sm text-center"
                type="text"
                data-cy="boatYard"
                id="boatYard" />
            </div>

            <div
              class="col-sm-4 mb-3 text-center">

              <label
                [for]="'boatModel'"
                class="form-label">{{ 'bvs.calculator.boatModel' | transloco }}</label>

              <input
                [formControl]="form.controls.bvsBoatInformation.controls.boatModel"
                class="form-control form-control-sm text-center"
                type="text"
                data-cy="boatModel"
                id="boatModel" />
            </div>
          </div>
        }
      }

      <div class="mb-5 mt-5 d-flex justify-content-center">
        <button
          (click)="prevClicked()"
          class="btn btn-sm btn-secondary">
          {{ 'shared.back' | transloco }}
        </button>

        @if (canNavigateNextForm()) {
          <button
            (click)="nextClicked()"
            class="btn btn-sm btn-primary"
            [attr.data-cy]="'toPremiumCalculation'">
            {{ 'bvs.calculator.toPremiumCalculation' | transloco }}
          </button>
        }
      </div>
    </div>
  </div>
</form>
