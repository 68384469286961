import { FormControl, FormGroup } from '@angular/forms';
import { SkipperFormModel } from '@shared/models/forms/skipper-form.model';
import { createCustomerDataForm } from '@shared/factories/customer-form.factory';
import { createSkipperRechtsschutzForm } from '../../features/skipper/factories/skipper-rechtsschutz-form.factory';
import { createSkipperHaftpflichtForm } from '../../features/skipper/factories/skipper-haftpflicht-form.factory';
import { CountryEnum } from '@brokernet/brokernet-ui-lib';

export function createSkipperForm(): FormGroup<SkipperFormModel> {
  return new FormGroup<SkipperFormModel>({
    customerData: createCustomerDataForm(),
    skipperHaftpflicht: createSkipperHaftpflichtForm(),
    skipperRechtsschutz: createSkipperRechtsschutzForm(),
    hasHaftpflicht: new FormControl<boolean>(false),
    hasRechtsschutz: new FormControl<boolean>(false),
    country: new FormControl<CountryEnum | null>(null)
  });
}

