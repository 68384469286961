import { Component, Input, OnInit } from '@angular/core';
import { NgClass } from '@angular/common';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoPipe } from '@ngneat/transloco';
import { CallidusCurrencyPipe } from '@shared/pipes/currency/callidus-currency.pipe';

@Component({
  selector: 'app-additional-coverage-box-for-enum',
  standalone: true,
  imports: [
    NgClass,
    ReactiveFormsModule,
    FormsModule,
    TranslocoPipe,
    CallidusCurrencyPipe
  ],
  providers: [CallidusCurrencyPipe],
  templateUrl: './additional-coverage-box-for-enum.component.html',
  styleUrl: './additional-coverage-box-for-enum.component.scss'
})
export class AdditionalCoverageBoxForEnumComponent<T> implements OnInit {

  @Input() title!: string;
  @Input() text!: string;
  @Input() icon!: string;
  @Input() ctrl!: FormControl<T | null>;

  _switchValues: [T, T] = [false as any, true as any];
  useCustomValues = false;
  switchValue = false;

  @Input() set switchValues(customSwitchValues: [T, T]) {
    this._switchValues = customSwitchValues;
    this.useCustomValues = true;
  }

  setControlValue(): void {
    this.ctrl.setValue(this.switchValue ? this._switchValues[1] : this._switchValues[0]);
  }

  toggleSwitchValueFromContainer(): void {
    this.switchValue = !this.switchValue;
    this.setControlValue();
  }

  ngOnInit(): void {
    if (this.ctrl.value === this._switchValues[1]) {
      this.switchValue = true;
    }
  }
}
