<p-overlayPanel #op>
  <ng-template pTemplate="content">
    @for (option of languageOptions;track $index) {
      @if (option.code !== currentLang.code) {
        <div
          class="lang-entry"
          (click)="entryClicked(option)">

          <img
            [src]="'assets/flags/' + option.flagIcon + '.png'"
            height="20px"
            [alt]="'flag-icon' + option.code" />

          {{ option.name | transloco }}
        </div>
      }
    }
  </ng-template>
</p-overlayPanel>

<button
  class="btn btn-sm btn-outline-dark language-button"
  (click)="op.toggle($event)">

  <i class="pi pi-globe"></i>
  {{ currentLang.code.toUpperCase() }}
</button>

