<div
  class="mb-5">

  <div class="row justify-content-center">
    <div class="col-12 mb-3">
      <h6 class="text-center">{{ 'bvs.calculator.person.contactDataTitle' | transloco }}</h6>
    </div>

    <div class="col-sm-3 mb-3 text-center">
      <label
        class="form-label"
        for="email">{{ 'shared.emailShort' | transloco }}</label>

      <input
        [formControl]="personForm.controls.email"
        class="form-control form-control-sm"
        data-cy="email"
        id="email"
        type="email">
    </div>

    <div class="col-sm-3 mb-3 text-center">
      <label
        class="form-label"
        for="phone">{{ 'customer.telephone' | transloco }}</label>

      <input
        [formControl]="personForm.controls.phone"
        class="form-control form-control-sm"
        data-cy="phone"
        id="phone"
        placeholder="+43..."
        type="text">
    </div>
  </div>
</div>
