import { Component, Input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { TranslocoPipe } from '@ngneat/transloco';
import { InputNumberModule } from 'primeng/inputnumber';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-pricing-data-box',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TranslocoPipe,
    InputNumberModule,
    CurrencyPipe
  ],
  templateUrl: './pricing-data-box.component.html',
  styleUrl: './pricing-data-box.component.scss'
})
export class PricingDataBoxComponent {

  @Input() title!: string;
  @Input() text!: string;
  @Input() inputId!: string;
  @Input() control!: FormControl<number | null>;
}
