import { Component, inject, Input, OnInit } from '@angular/core';
import { AdditionalCoverageBoxComponent } from '@shared/components/additional-coverage-box/additional-coverage-box.component';
import { CalculationErrorDisplayComponent } from '@shared/components/calculation-error-display/calculation-error-display.component';
import { PaginatorModule } from 'primeng/paginator';
import { PremiumDisplayComponent } from '@shared/components/premium-display/premium-display.component';
import { TranslocoPipe } from '@ngneat/transloco';
import { DataBaseComponent } from '@shared/directives/data-base.component';
import { FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { BvsCharterKautionsversicherungFormModel } from '../../models/forms/bvs-charter-kautionsversicherung-form.model';
import { AnswerOption, QuestionBoxComponent } from '@shared/components/question-box/question-box.component';
import { BoatTypeEnum } from '@shared/models/boat-type.enum';
import { takeUntil } from 'rxjs';
import { CharterkautionApiService } from '../../services/charterkaution-api.service';
import { BvsCharterKautionsversicherungModel } from '../../models/bvs-charter-kautionsversicherung.model';
import { CalculationResultModel } from '@shared/models/calculation-result.model';
import { isFormGroupInvalidExcept } from '@brokernet/brokernet-ui-lib';
import { NgClass } from '@angular/common';
import { CalculationResultStatusEnum } from '@shared/models/calculation-result-status.enum';
import { CharterCalculationResultModel } from '@shared/models/charter-calculation-result.model';
import { countryAnswerOptions } from '@shared/models/country-registered-answer-options';
import { defaultAnswerOptions } from '@shared/models/default-answer-options';

@Component({
  selector: 'app-charter-kautionsversicherung',
  standalone: true,
  imports: [
    AdditionalCoverageBoxComponent,
    CalculationErrorDisplayComponent,
    PaginatorModule,
    PremiumDisplayComponent,
    TranslocoPipe,
    ReactiveFormsModule,
    QuestionBoxComponent,
    NgClass
  ],
  providers: [CharterkautionApiService],
  templateUrl: './charter-kautionsversicherung.component.html',
  styleUrl: './charter-kautionsversicherung.component.scss'
})
export class CharterKautionsversicherungComponent extends DataBaseComponent implements OnInit {

  bvsCharterkautionApiService = inject(CharterkautionApiService);

  @Input() form!: FormGroup<BvsCharterKautionsversicherungFormModel>;

  calculationResult: CalculationResultModel | null = null;

  boatTypeAnswerOptions: AnswerOption<BoatTypeEnum>[] = [
    { title: 'bvs.motoryachtMotorboot', id: BoatTypeEnum.MOTORBOOT_MOTORYACHT, value: BoatTypeEnum.MOTORBOOT_MOTORYACHT },
    { title: 'bvs.segelyachtSegelboot', id: BoatTypeEnum.SEGELBOOT_SEGELYACHT, value: BoatTypeEnum.SEGELBOOT_SEGELYACHT }
  ];

  protected readonly countryAnswerOptions = countryAnswerOptions;
  protected readonly defaultAnswerOptions = defaultAnswerOptions;

  ngOnInit(): void {
    this.form.controls.hasCharterFolgeschadenVersicherung.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(value => {
        if (value) {
          this.setYachtlengthValidator();
        } else {
          this.removeYachtlengthValidator();
        }
      });

    this.calculate();

    this.form.valueChanges.pipe(takeUntil(this.componentDestroyed$)).subscribe(() => this.calculate());
  }

  calculate(): void {
    if (!this.isFormValidForPremiumCalculation()) return;
    const cmd: BvsCharterKautionsversicherungModel = {
      ...this.form.getRawValue(),
      yachtLength: this.form.controls.hasCharterFolgeschadenVersicherung.value ? this.form.controls.yachtLength.value : null
    };
    this.bvsCharterkautionApiService.calculate(cmd)
      .subscribe((result) => this.handleCalculationResultSuccess(result));
  }

  isFormValidForPremiumCalculation(): boolean {
    return !isFormGroupInvalidExcept(this.form, ['customerData']);
  }

  handleCalculationResultSuccess(result: CharterCalculationResultModel): void {
    this.calculationResult = {
      result: result.totalResult,
      calculationResultStatus: CalculationResultStatusEnum.OK,
      calculationResultStatusDetails: []
    };
  }

  private setYachtlengthValidator(): void {
    this.form.controls.yachtLength.addValidators([
      Validators.required,
      Validators.max(63)
    ]);

  }

  private removeYachtlengthValidator(): void {
    this.form.controls.yachtLength.removeValidators([
      Validators.required,
      Validators.max(63)
    ]);
    this.form.controls.yachtLength.updateValueAndValidity();
  }
}
