import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { SharedModule } from 'primeng/api';
import { AppLanguage } from '@shared/models/app-language.model';
import { TranslocoPipe } from '@ngneat/transloco';

@Component({
  selector: 'app-language-picker',
  standalone: true,
  imports: [
    OverlayPanelModule,
    SharedModule,
    TranslocoPipe
  ],
  templateUrl: './language-picker.component.html',
  styleUrl: './language-picker.component.scss'
})
export class LanguagePickerComponent {

  @ViewChild('op', { static: false }) op!: OverlayPanel;

  @Input() languageOptions!: AppLanguage[];

  currentLang!: AppLanguage;

  @Input() set selectedLanguage(lang: AppLanguage) {
    this.currentLang = lang;
  }

  @Output() languageChange = new EventEmitter<AppLanguage>();

  entryClicked(lang: AppLanguage): void {
    this.languageChange.emit(lang);
    this.op.hide();
  }
}
