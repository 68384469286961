import { Component, Input, OnInit } from '@angular/core';
import { InputNumberModule } from 'primeng/inputnumber';

import { PaginatorModule } from 'primeng/paginator';
import { AnswerOption, QuestionBoxComponent } from '@shared/components/question-box/question-box.component';
import { TranslocoPipe } from '@ngneat/transloco';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SailingAreaEnum, SailingAreaValues } from '@shared/models/sailing-area.enum';
import { CountryRegisteredEnum } from '@shared/models/country-registered.enum';
import { HullMaterialEnumValues } from '@shared/models/hull-material.enum';
import { BoatTypeEnum } from '@shared/models/boat-type.enum';
import { EnumLabelKeysModel } from '@brokernet/brokernet-ui-lib';
import { takeUntil } from 'rxjs';
import { BvsNepKundeEnum } from '@shared/models/bvs-nep-kunde.enum';
// eslint-disable-next-line no-restricted-imports
import { AsyncPipe, NgForOf } from '@angular/common';
import { BvsHaftpflichtUndKaskoFormModel } from '../../models/form/bvs-haftpflicht-und-kasko-form.model';
import { BoatDataBaseComponent } from '@shared/directives/boat-data-base.component';
import { countryRegisteredAnswerOptions } from '@shared/models/country-registered-answer-options';
import { defaultAnswerOptions } from '@shared/models/default-answer-options';

@Component({
  selector: 'app-boat-data-haftpflicht-kasko',
  standalone: true,
  imports: [
    InputNumberModule,
    PaginatorModule,
    QuestionBoxComponent,
    TranslocoPipe,
    ReactiveFormsModule,
    NgForOf,
    AsyncPipe
  ],
  templateUrl: './boat-data-haftpflicht-kasko.component.html',
  styleUrl: './boat-data-haftpflicht-kasko.component.scss'
})
export class BoatDataHaftpflichtKaskoComponent extends BoatDataBaseComponent implements OnInit {

  @Input() form!: FormGroup<BvsHaftpflichtUndKaskoFormModel>;

  hullMaterialValues = HullMaterialEnumValues.getLabelKeys();

  countryRegisteredAnswerOptions = countryRegisteredAnswerOptions;

  sailingAreaAnswerOptions: AnswerOption<SailingAreaEnum>[] = [
    {
      title: SailingAreaValues.getEnumByKey(SailingAreaEnum.BINNENSEE)?.label!,
      id: SailingAreaEnum.BINNENSEE,
      value: SailingAreaEnum.BINNENSEE
    },
    {
      title: SailingAreaValues.getEnumByKey(SailingAreaEnum.MITTELMEER_NORD_UND_OSTSEE)?.label!,
      id: SailingAreaEnum.MITTELMEER_NORD_UND_OSTSEE,
      value: SailingAreaEnum.MITTELMEER_NORD_UND_OSTSEE
    }
  ];
  protected readonly defaultAnswerOptions = defaultAnswerOptions;

  ngOnInit(): void {
    const infoCtrls = this.form.controls.bvsBoatInformation.controls;

    infoCtrls
      .countryRegistered
      .valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => {
        /**
         * wenn der User DE oder AT als CountryRegistered auswählt hat er die Wahl,
         * ob das Boot nur in Binnengewässern oder auch im Mittelmeer segelt
         *
         * daher können wir, wenn das Land nicht AT oder DE ist, sailingArea immer auf Mittelmeer setzen,
         * da die anderen Länder alle am Mittelmeer liegen
         */
        if (!this.canShowSailingArea()) {
          infoCtrls.sailingArea.setValue(SailingAreaEnum.MITTELMEER_NORD_UND_OSTSEE);
        }
      });

    // wenn die SailingArea Mittelmeer ist, soll der SB in der Haftpflicht per default höher sein
    infoCtrls
      .sailingArea
      .valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(sailingArea => {
        if (sailingArea === SailingAreaEnum.MITTELMEER_NORD_UND_OSTSEE) {
          this.form.controls.bvsFigures.controls.bvsNepKunde.setValue(BvsNepKundeEnum.NEP10MIO);
        }
      });

  }

  canShowConstructionType(): boolean {
    const infoCtrls = this.form.controls.bvsBoatInformation.controls;
    const isMotorbootMotoryacht = infoCtrls.boatType.value === BoatTypeEnum.MOTORBOOT_MOTORYACHT;
    const hasMaxKnots = infoCtrls.maxKnotsOver40.value !== null && infoCtrls.maxKnotsOver40.valid;
    return isMotorbootMotoryacht && hasMaxKnots;
  }

  canShowHasDinghy(): boolean {
    const infoCtrls = this.form.controls.bvsBoatInformation.controls;

    if (infoCtrls.boatType.value === BoatTypeEnum.MOTORBOOT_MOTORYACHT) {
      return infoCtrls.boatConstructionType.value !== null;
    } else if (infoCtrls.boatType.value === BoatTypeEnum.SEGELBOOT_SEGELYACHT) {
      return infoCtrls.constructionYear.value !== null;
    }

    return false;
  }

  canShowHasModifications(): boolean {
    return this.form.controls.bvsBoatInformation.controls.hasDinghy.value !== null;
  }

  canShowBoatMaterialDetails(): boolean {
    const infoCtrls = this.form.controls.bvsBoatInformation.controls;
    if (infoCtrls.isBoatModified.value === null || infoCtrls.isBoatModified.value === undefined) {
      return false;
    }

    return infoCtrls.isBoatModified.value && infoCtrls.boatModifiedValue.value !== null || !infoCtrls.isBoatModified.value;
  }

  canShowSailingArea(): boolean {
    const infoCtrls = this.form.controls.bvsBoatInformation.controls;

    if (infoCtrls.countryRegistered.value === null) {
      return false;
    }

    return infoCtrls.countryRegistered.value === CountryRegisteredEnum.AUT
      || infoCtrls.countryRegistered.value === CountryRegisteredEnum.GER;
  }

  canNavigateNextForm(): boolean {
    const boatInformationCtrls = this.form.controls.bvsBoatInformation.controls;

    const hasModelAndYard = !!boatInformationCtrls.boatModel.value && !!boatInformationCtrls.boatYard.value;

    if (!hasModelAndYard) {
      return false;
    }

    if (boatInformationCtrls.boatType.value === BoatTypeEnum.SEGELBOOT_SEGELYACHT) {
      return this.canNavigateNextForSailBoat();
    }

    return true;
  }

  private canNavigateNextForSailBoat(): boolean {
    const boatInformationCtrls = this.form.controls.bvsBoatInformation.controls;
    return !!boatInformationCtrls.mastMaterial.value && !!boatInformationCtrls.sailArea.value;
  }

  isNotGermanyOrAustriaOrNull(): boolean {
    const infoCtrls = this.form.controls.bvsBoatInformation.controls;
    return infoCtrls.countryRegistered.value !== CountryRegisteredEnum.AUT
      && infoCtrls.countryRegistered.value !== CountryRegisteredEnum.GER
      && infoCtrls.countryRegistered.value !== null;
  }

  prevClicked(): void {
    this.prev.emit();
  }

  nextClicked(): void {
    this.next.emit();
  }
}

export enum SailingAndEngineOptionEnum {
  OnlyEngine = 'OnlyEngine',
  OnlySail = 'OnlySail',
  SailAndEngine = 'SailAndEngine',
  Nothing = 'Nothing'
}

export namespace SailingAndEngineOptionEnumValues {
  export function getLabelKeys(): EnumLabelKeysModel<SailingAndEngineOptionEnum>[] {
    return [
      { key: SailingAndEngineOptionEnum.OnlyEngine, label: 'bvs.calculator.onlyEngine' },
      { key: SailingAndEngineOptionEnum.OnlySail, label: 'bvs.calculator.onlySail' },
      { key: SailingAndEngineOptionEnum.SailAndEngine, label: 'bvs.calculator.sailAndEngine' },
      { key: SailingAndEngineOptionEnum.Nothing, label: 'bvs.calculator.nothing' }
    ];
  }

  export function getEnumByKey(key: SailingAndEngineOptionEnum): EnumLabelKeysModel<SailingAndEngineOptionEnum> | undefined {
    return getLabelKeys().find(k => k.key === key);
  }
}
