import { Component, Input, OnInit } from '@angular/core';
import { InputNumberModule } from 'primeng/inputnumber';

import { PaginatorModule } from 'primeng/paginator';
import { QuestionBoxComponent } from '@shared/components/question-box/question-box.component';
import { TranslocoPipe } from '@ngneat/transloco';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SailingAreaEnum } from '@shared/models/sailing-area.enum';
import { CountryRegisteredEnum } from '@shared/models/country-registered.enum';
import { BoatTypeEnum } from '@shared/models/boat-type.enum';
import { AsyncPipe } from '@angular/common';
import { BvsHaftpflichtFormModel } from '../../models/form/bvs-haftpflicht-form.model';
import { BoatDataBaseComponent } from '@shared/directives/boat-data-base.component';
import { countryRegisteredAnswerOptions } from '@shared/models/country-registered-answer-options';
import { defaultAnswerOptions } from '@shared/models/default-answer-options';

@Component({
  selector: 'app-boat-data-haftpflicht',
  standalone: true,
  imports: [
    InputNumberModule,
    PaginatorModule,
    QuestionBoxComponent,
    TranslocoPipe,
    ReactiveFormsModule,
    AsyncPipe
  ],
  templateUrl: './boat-data-haftpflicht.component.html',
  styleUrl: './boat-data-haftpflicht.component.scss'
})
export class BoatDataHaftpflichtComponent extends BoatDataBaseComponent implements OnInit {

  @Input() form!: FormGroup<BvsHaftpflichtFormModel>;

  countryRegisteredAnswerOptions = countryRegisteredAnswerOptions
    .filter(x => x.value === CountryRegisteredEnum.AUT || x.value === CountryRegisteredEnum.GER);
  protected readonly defaultAnswerOptions = defaultAnswerOptions;

  ngOnInit(): void {
    this.form.controls.bvsBoatInformation.controls.sailingArea.setValue(SailingAreaEnum.BINNENSEE);
  }

  canShowConstructionType(): boolean {
    const infoCtrls = this.form.controls.bvsBoatInformation.controls;
    const isMotorbootMotoryacht = infoCtrls.boatType.value === BoatTypeEnum.MOTORBOOT_MOTORYACHT;
    const hasMaxKnots = infoCtrls.maxKnotsOver40.value !== null;
    return hasMaxKnots && isMotorbootMotoryacht;

  }

  canShowHasDinghy(): boolean {
    const infoCtrls = this.form.controls.bvsBoatInformation.controls;

    if (infoCtrls.boatType.value === BoatTypeEnum.MOTORBOOT_MOTORYACHT) {
      return infoCtrls.boatConstructionType.value !== null;
    } else if (infoCtrls.boatType.value === BoatTypeEnum.SEGELBOOT_SEGELYACHT) {
      return !!this.form.controls.bvsBoatInformation.controls.countryRegistered.value;
    }
    return false;
  }

  canShowBoatMaterialDetails(): boolean {
    return this.form.controls.bvsBoatInformation.controls.hasDinghy.value !== null;

  }

  canNavigateNextForm(): boolean {
    const boatInformationCtrls = this.form.controls.bvsBoatInformation.controls;

    const hasModelAndYard = !!boatInformationCtrls.boatModel.value && !!boatInformationCtrls.boatYard.value;

    if (!hasModelAndYard) {
      return false;
    }

    if (boatInformationCtrls.boatType.value === BoatTypeEnum.SEGELBOOT_SEGELYACHT) {
      return this.canNavigateNextForSailBoat();
    }

    return true;
  }

  canShowYardForSailBoat(): boolean {
    return this.form.controls.bvsBoatInformation.controls.sailArea.value !== null;
  }

  canShowYardForMotorboat(): boolean {
    return this.form.controls.bvsBoatInformation.controls.engineHp.value !== null;
  }

  private canNavigateNextForSailBoat(): boolean {
    const boatInformationCtrls = this.form.controls.bvsBoatInformation.controls;
    return !!boatInformationCtrls.mastMaterial.value && !!boatInformationCtrls.sailArea.value;
  }

  prevClicked(): void {
    this.prev.emit();
  }

  nextClicked(): void {
    this.next.emit();
  }
}
