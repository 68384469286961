import { Component, inject, Input, OnInit } from '@angular/core';
import { PremiumDisplayComponent } from '@shared/components/premium-display/premium-display.component';
import { QuestionBoxComponent } from '@shared/components/question-box/question-box.component';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SkipperHaftpflichtComponent } from '../skipper-haftpflicht/skipper-haftpflicht.component';
import { SkipperRechtsschutzComponent } from '../skipper-rechtsschutz/skipper-rechtsschutz.component';
import { TranslocoPipe } from '@ngneat/transloco';
import { AdditionalCoverageBoxComponent } from '@shared/components/additional-coverage-box/additional-coverage-box.component';
import { defaultAnswerOptions } from '@shared/models/default-answer-options';
import { CalculationErrorDisplayComponent } from '@shared/components/calculation-error-display/calculation-error-display.component';
import { NgClass } from '@angular/common';
import { SkipperFormModel } from '@shared/models/forms/skipper-form.model';
import { SkipperCalculationService } from '../../services/skipper-calculation.service';
import { DataBaseComponent } from '@shared/directives/data-base.component';

@Component({
  selector: 'app-skipper-rechtsschutz-details',
  standalone: true,
  imports: [
    PremiumDisplayComponent,
    QuestionBoxComponent,
    ReactiveFormsModule,
    SkipperHaftpflichtComponent,
    SkipperRechtsschutzComponent,
    TranslocoPipe,
    AdditionalCoverageBoxComponent,
    CalculationErrorDisplayComponent,
    NgClass
  ],
  templateUrl: './skipper-rechtsschutz-details.component.html',
  styleUrl: './skipper-rechtsschutz-details.component.scss'
})
export class SkipperRechtsschutzDetailsComponent extends DataBaseComponent implements OnInit {

  skipperCalculationService = inject(SkipperCalculationService);
  @Input() form!: FormGroup<SkipperFormModel>;

  ngOnInit(): void {
    this.skipperCalculationService.calculate(this.form);
  }

  isValid(): boolean {
    if (!this.form.controls.hasHaftpflicht.value && !this.form.controls.hasRechtsschutz.value) {
      return false;
    }
    if (this.form.controls.hasRechtsschutz.value) {
      const ctrls = this.form.controls.skipperRechtsschutz.controls;
      return !!ctrls.isSailAreaExplicitlyInEurope.value &&
        !!ctrls.isBoatExplicitlyUsedPrivately.value &&
        (!ctrls.hasAlreadyHadDamagesOrLegalDispute.value ||
          !!ctrls.hasAlreadyHadDamagesOrLegalDisputeDescription.value);

    }
    if (this.form.controls.hasHaftpflicht.value) {
      return true;
    }
    throw new Error('this should not happen');
  }

  protected readonly defaultAnswerOptions = defaultAnswerOptions;

}
