import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ContractTypeEnum, CountryEnum } from '@brokernet/brokernet-ui-lib';
import { createCustomerDataForm } from '@shared/factories/customer-form.factory';
import { BvsCharterKautionsversicherungFormModel } from '../models/forms/bvs-charter-kautionsversicherung-form.model';
import { BoatTypeEnum } from '@shared/models/boat-type.enum';

export function createBvsCharterKautionsversicherungForm(): FormGroup<BvsCharterKautionsversicherungFormModel> {
  return new FormGroup<BvsCharterKautionsversicherungFormModel>({
    boatType: new FormControl<BoatTypeEnum | null>(null, [Validators.required]),
    kautionsBetrag: new FormControl<number | null>(null, [Validators.max(6000), Validators.required]),
    hasCharterFolgeschadenVersicherung: new FormControl<boolean | null>(null, [Validators.required]),
    yachtLength: new FormControl<number | null>(null),
    customerData: createCustomerDataForm(),
    contractType: new FormControl<ContractTypeEnum>(ContractTypeEnum.CHARTER_KAUTION),
    country: new FormControl<CountryEnum | null>(null)
  });
}

