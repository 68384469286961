import { OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

export type Constructor<T = {}> = new (...args: any[]) => T;

export const unsubscribeMixin = <T extends Constructor>(base: T = class {
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
} as T) =>
  class extends base implements OnDestroy {
    componentDestroyed$ = new Subject<void>();

    ngOnDestroy(): void {
      this.componentDestroyed$.next();
      this.componentDestroyed$.complete();
    }
  };
