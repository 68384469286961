<form
  [formGroup]="form"
  class="container">
  <div class="row justify-content-center">
    <div class="col-12 col-md-9 col-lg-6">
      <div class="mb-4">
        <app-question-box
          (answerSelect)="setValueToForm(form.controls.skipperHaftpflicht.controls.hasBeantragteRisikenGekuendigtOderAbgelehnt, $event.value)"
          [answers]="defaultAnswerOptions"
          [id]="'has-had-risks'"
          [selectedValue]="form.controls.skipperHaftpflicht.controls.hasBeantragteRisikenGekuendigtOderAbgelehnt.value"
          [title]="'bvs.calculator.hasBeantragteRisikenGekuendigtOderAbgelehntTitle'"></app-question-box>
      </div>

      @if (form.controls.skipperHaftpflicht.controls.hasBeantragteRisikenGekuendigtOderAbgelehnt.value) {
        <div class="row mb-1 justify-content-center">
          <div
            class="col-sm-6 mb-3 text-center">
            <label
              [for]="'versicherungsgesellschaft'"
              class="form-label">{{ 'bvs.calculator.versicherungsgesellschaft' | transloco }}</label>

            <input
              [formControl]="form.controls.skipperHaftpflicht.controls.versicherungsgesellschaftGekuendigtOderAbgelehnt"
              class="form-control form-control-sm text-center"
              type="text"
              data-cy="versicherungsgesellschaft"
              id="versicherungsgesellschaft" />
          </div>

          <div
            class="col-sm-6 mb-3 text-center">
            <label
              [for]="'year'"
              class="form-label">{{ 'bvs.calculator.year' | transloco }}</label>

            <input
              [formControl]="form.controls.skipperHaftpflicht.controls.yearGekuendigtOderAbgelehnt"
              class="form-control form-control-sm text-center"
              type="number"
              data-cy="year"
              id="year" />
          </div>
        </div>
        <div class="row mb-1 justify-content-center">
          <div
            class="col-sm-6 mb-3 text-center">
            <label
              [for]="'policyNumber'"
              class="form-label">{{ 'bvs.calculator.policyNumber' | transloco }}</label>

            <input
              [formControl]="form.controls.skipperHaftpflicht.controls.policyNumberGekuendigtOderAbgelehnt"
              class="form-control form-control-sm text-center"
              type="text"
              data-cy="policyNumber"
              id="policyNumber" />
          </div>

          <div
            class="col-sm-6 mb-3 text-center">
            <label
              [for]="'sparte'"
              class="form-label">{{ 'bvs.calculator.sparte' | transloco }}</label>

            <input
              [formControl]="form.controls.skipperHaftpflicht.controls.sparteGekuendigtOderAbgelehnt"
              class="form-control form-control-sm text-center"
              type="text"
              data-cy="sparte"
              id="sparte" />
          </div>
        </div>

        <div class="row mb-5 justify-content-center">
          <div
            class="col-sm-12 mb-3 text-center">
            <label
              [for]="'reason'"
              class="form-label">{{ 'bvs.calculator.reason' | transloco }}</label>

            <textarea
              [formControl]="form.controls.skipperHaftpflicht.controls.grundGekuendigtOderAbgelehnt"
              class="form-control form-control-sm h-100"
              data-cy="reason"
              type="text"
              id="reason"></textarea>
          </div>
        </div>
      }

      @if (canShowHasSchaedenBereitsErlitten() || this.form.controls.skipperHaftpflicht.controls.hasSchaedenBereitsErlitten.value !== null) {
        <div class="mb-4">
          <app-question-box
            [id]="'has-had-damages'"
            (answerSelect)="setValueToForm(form.controls.skipperHaftpflicht.controls.hasSchaedenBereitsErlitten, $event.value)"
            [answers]="defaultAnswerOptions"
            [selectedValue]="form.controls.skipperHaftpflicht.controls.hasSchaedenBereitsErlitten.value"
            [title]="'bvs.calculator.hasSchaedenBereitsErlittenTitle'"></app-question-box>
        </div>
      }

      @if (form.controls.skipperHaftpflicht.controls.hasSchaedenBereitsErlitten.value !== null) {
        <div class="mb-4">
          <app-question-box
            [id]="'has-risks-insured'"
            (answerSelect)="setValueToForm(form.controls.skipperHaftpflicht.controls.hasRisikenBereitsVersichert, $event.value)"
            [answers]="defaultAnswerOptions"
            [selectedValue]="form.controls.skipperHaftpflicht.controls.hasRisikenBereitsVersichert.value"
            [title]="'bvs.calculator.hasRisikenBereitsVersichertTitle'"></app-question-box>
        </div>
      }

      <div
        [ngClass]="getPremiumColorCode(skipperCalculationService.calculationResult)"
        class="card-body mb-4 box-shadow">

        <div
          class="mb-5">
          <app-premium-display
            [premiumValue]="skipperCalculationService.calculationResult?.result!"></app-premium-display>
          @if (skipperCalculationService.showErrorMessages()) {
            <app-calculation-error-display
              [result]="skipperCalculationService.calculationResult"></app-calculation-error-display>
          }
        </div>
      </div>

      <div class="mb-5 mt-5 d-flex justify-content-center">
        <button
          (click)="prev.emit()"
          class="btn btn-sm btn-secondary">
          {{ 'shared.back' | transloco }}
        </button>
        <button
          (click)="next.emit()"
          [disabled]="this.form.controls.skipperHaftpflicht.invalid || !canShowHasSchaedenBereitsErlitten()"
          class="btn btn-sm btn-primary"
          data-cy="continueToRechtsschutzOrCustomer">
          @if (this.form.controls.hasRechtsschutz.value) {
            {{ 'bvs.calculator.continueToRechtsschutzDetails' | transloco }}
          } @else {
            {{ 'bvs.calculator.continueToCustomerData' | transloco }}
          }
        </button>
      </div>
    </div>
  </div>
</form>
