import { Directive, ElementRef, inject, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[appButtonBusy]',
  standalone: true
})
export class ButtonBusyDirective implements OnInit {

  _element = inject(ElementRef);

  @Input() set busyBtnNoMargin(noMargin: boolean) {
    this._busyBtnNoMargin = noMargin;
  }
  @Input() set isButtonBusy(isBusy: boolean) {
    this.refreshState(isBusy);
  }

  private _button: any;
  private _originalButtonInnerHtml: any;
  private _iconHTML: any;
  private _busyBtnNoMargin: boolean=false;

  ngOnInit(): void {
    this._button = this._element.nativeElement;
  }

  refreshState(isBusy: boolean): void {
    if (!this._button) {
      return;
    }
    if (!this._originalButtonInnerHtml) this._originalButtonInnerHtml = this._button.innerHTML;

    if (isBusy) {
      // disable button
      this._button.setAttribute('disabled', 'disabled');
      const iElement = [].slice.call(this._button.children).find(c =>(c as any).tagName === 'I');
      if (iElement) {
        this._iconHTML = (iElement as HTMLElement).outerHTML;
        this._button.innerHTML = this._button.innerHTML.replace(this._iconHTML, '');
      }

      this._button.innerHTML = `<i class="pi pi-spin pi-spinner ${!this._busyBtnNoMargin ? '' : 'spinner-icon'}"></i>` +
        '<span>' + this._button.innerHTML + '</span>';

      this._button.setAttribute('_disabledBefore', true);
    } else {
      if (!this._button.getAttribute('_disabledBefore')) {
        return;
      }

      // enable button
      this._button.removeAttribute('disabled');
      this._button.innerHTML = this._originalButtonInnerHtml;
      this._originalButtonInnerHtml = false;
    }
  }
}
