import { inject, Injectable } from '@angular/core';
import { SkipperFormModel } from '@shared/models/forms/skipper-form.model';
import { GetVersicherungsPraemieSkipperCommandModel } from '@shared/models/commands/get-versicherungs-praemie-command.model';
import { SkipperCalculationResultModel } from '@shared/models/skipper-calculation-result.model';
import { FormGroup } from '@angular/forms';
import { SkipperApiService } from '@shared/services/skipper-api.service';
import { CalculationResultModel } from '@shared/models/calculation-result.model';
import { CalculationResultStatusEnum } from '@shared/models/calculation-result-status.enum';

@Injectable({
  providedIn: 'root'
})
export class SkipperCalculationService {
  skipperApiService = inject(SkipperApiService);

  calculationResult: CalculationResultModel | null = null;

  calculate(form: FormGroup<SkipperFormModel>): void {
    const cmd: GetVersicherungsPraemieSkipperCommandModel = {
      hasSelbstbehalt: form.controls.hasRechtsschutz.value ? form.controls.skipperRechtsschutz.controls.hasSelbstbehalt.value : null,
      skipperVersicherungssummeKunde: form.controls.hasHaftpflicht.value ? form.controls.skipperHaftpflicht.controls.skipperVersicherungssummeKunde.value : null,
      hasCharterVertragsRechtsschutz: form.controls.hasRechtsschutz.value ? form.controls.skipperRechtsschutz.controls.hasCharterVertragsRechtsschutz.value : null,
      hasBeantragteRisikenGekuendigtOderAbgelehnt: form.controls.hasHaftpflicht.value ? form.controls.skipperHaftpflicht.controls.hasBeantragteRisikenGekuendigtOderAbgelehnt.value : null,
      hasSchaedenBereitsErlitten: form.controls.hasHaftpflicht.value ? form.controls.skipperHaftpflicht.controls.hasSchaedenBereitsErlitten.value : null,
      hasRisikenBereitsVersichert: form.controls.hasHaftpflicht.value ? form.controls.skipperHaftpflicht.controls.hasRisikenBereitsVersichert.value : null
    };
    this.skipperApiService
      .calculate(cmd)
      .subscribe((result: SkipperCalculationResultModel) => {
        this.calculationResult = {
          result: result.totalResult,
          calculationResultStatus: result.calculationResultStatus,
          calculationResultStatusDetails: result.calculationResultStatusDetails
        };
      });
  }

  showErrorMessages(): boolean {
    return this.calculationResult !== null &&
      (this.calculationResult.calculationResultStatus === CalculationResultStatusEnum.OK_WITH_WARNING ||
        this.calculationResult.calculationResultStatus === CalculationResultStatusEnum.ERROR);
  }
}
