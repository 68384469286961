import { FormControl, FormGroup } from '@angular/forms';
import { ContractTypeEnum } from '@brokernet/brokernet-ui-lib';
import { createCustomerDataForm } from '@shared/factories/customer-form.factory';
import { BvsHaftfplichtFiguresFormModel, BvsHaftpflichtBoatInformationFormModel, BvsHaftpflichtFormModel } from '../models/form/bvs-haftpflicht-form.model';
import { createBvsFiguresBaseForm } from '@shared/factories/bvs-figures-base.factory';
import { createBvsBoatInformationBaseForm } from '@shared/factories/bvs-boat-information-form.factory';

export function createBvsHaftpflichtForm(): FormGroup<BvsHaftpflichtFormModel> {
  return new FormGroup<BvsHaftpflichtFormModel>({
    bvsBoatInformation: new FormGroup<BvsHaftpflichtBoatInformationFormModel>(createBvsBoatInformationBaseForm()),
    figures: new FormGroup<BvsHaftfplichtFiguresFormModel>(createBvsFiguresBaseForm()),
    customerData: createCustomerDataForm(),
    contractType: new FormControl<ContractTypeEnum>(ContractTypeEnum.BVS_HAFTPFLICHT)
  });
}

