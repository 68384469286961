<div class="additional-coverage-box pointer">
  <div
    (click)="toggleSwitchValueFromContainer()"
    class="d-flex">
    <div class="flex-grow-0 icon">
      <i
        [ngClass]="icon"
        class="pi"></i>
    </div>
    <div class="flex-grow-1 texts">
      <div class="title">{{ title | transloco }}</div>
      <div class="text">{{ text | transloco }}</div>
    </div>
    <div class="flex-grow-0 checkbox">
      <div class="form-check form-switch">
        <input
          [formControl]="ctrl"
          [id]="id"
          class="form-check-input pointer"
          role="switch"
          type="checkbox">
      </div>
    </div>
  </div>
</div>
