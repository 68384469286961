import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HullMaterialEnum } from '@shared/models/hull-material.enum';
import { SelbstbehaltKundeEnum } from '@shared/models/selbstbehalt-kunde.enum';
import {
  BvsHaftpflichtUndKaskoBoatInformationFormModel,
  BvsHaftpflichtUndKaskoFiguresFormModel,
  BvsHaftpflichtUndKaskoFormModel
} from '../models/form/bvs-haftpflicht-und-kasko-form.model';
import { ContractTypeEnum } from '@brokernet/brokernet-ui-lib';
import { createCustomerDataForm } from '@shared/factories/customer-form.factory';
import { createBvsFiguresBaseForm } from '@shared/factories/bvs-figures-base.factory';
import { createBvsBoatInformationBaseForm } from '@shared/factories/bvs-boat-information-form.factory';

export function createBvsHaftpflichtKaskoForm(): FormGroup<BvsHaftpflichtUndKaskoFormModel> {
  return new FormGroup<BvsHaftpflichtUndKaskoFormModel>({
    bvsBoatInformation: new FormGroup<BvsHaftpflichtUndKaskoBoatInformationFormModel>(createBvsBoatInformationHaftpflichtKaskoForm()),
    bvsFigures: new FormGroup<BvsHaftpflichtUndKaskoFiguresFormModel>(createBvsFiguresHaftpflichtKaskoForm()),
    customerData: createCustomerDataForm(),
    contractType: new FormControl<ContractTypeEnum>(ContractTypeEnum.BVS_HAFTPFLICHT_UND_KASKO)
  });
}

function createBvsBoatInformationHaftpflichtKaskoForm(): BvsHaftpflichtUndKaskoBoatInformationFormModel {
  const commonForm = createBvsBoatInformationBaseForm();
  return {
    ...commonForm,
    isSport: new FormControl<boolean | null>(null),
    isBoatModified: new FormControl<boolean | null>(null),
    boatModifiedValue: new FormControl<string | null>(null),
    constructionYear: new FormControl<number | null>(null, Validators.required),
    hullMaterial: new FormControl<HullMaterialEnum | null>(null, Validators.required),
    fullWidth: new FormControl<number | null>(null),
    fullLength: new FormControl<number | null>(null)
  };
}

function createBvsFiguresHaftpflichtKaskoForm(): BvsHaftpflichtUndKaskoFiguresFormModel {
  const commonForm = createBvsFiguresBaseForm();

  return {
    ...commonForm,
    selbstbehaltKunde: new FormControl<SelbstbehaltKundeEnum | null>(null, Validators.required),
    isMaschinendeckung: new FormControl<boolean | null>(false),
    insuranceSumBoat: new FormControl<number | null>(null),
    insuranceSumAdditionalEquipment: new FormControl<number | null>(null),
    insuranceSumPersoenlicheEffekte: new FormControl<number | null>(null),
    insuranceSumTrailer: new FormControl<number | null>(null),
    insuranceSumDinghy: new FormControl<number | null>(null),
    totalInsuranceSum: new FormControl<number | null>(null),
    allPricesWithVAT: new FormControl<boolean | null>(null)
  };
}




