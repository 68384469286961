import { EnumLabelKeysModel } from '@brokernet/brokernet-ui-lib';

export enum BoatConstructionTypeEnum {
  VERDRAENGER = 'VERDRAENGER',
  GLEITER = 'GLEITER',
}

export namespace BoatConstructionTypeEnumValues {
  export function getLabelKeys(): EnumLabelKeysModel<BoatConstructionTypeEnum>[] {
    return [
      { key: BoatConstructionTypeEnum.VERDRAENGER, label: 'bvs.verdraenger' },
      { key: BoatConstructionTypeEnum.GLEITER, label: 'bvs.gleiter' }
    ];
  }

  export function getEnumByKey(key: BoatConstructionTypeEnum): EnumLabelKeysModel<BoatConstructionTypeEnum> | undefined {
    return getLabelKeys().find(k => k.key === key);
  }
}
