import { EnumLabelKeysModel } from '@brokernet/brokernet-ui-lib';

export enum BoatTypeEnum {
  SEGELBOOT_SEGELYACHT = 'SEGELBOOT_SEGELYACHT',
  MOTORBOOT_MOTORYACHT = 'MOTORBOOT_MOTORYACHT',
  TRIMARAN = 'TRIMARAN',
  KATAMARAN = 'KATAMARAN',
  ZILLE = 'ZILLE',
  JETSKI = 'JETSKI',
  SCHNELLBOOTE = 'SCHNELLBOOTE',
  KLASSIKBOOT = 'KLASSIKBOOT'
}

export namespace BoatTypeEnumValues {
  export function getLabelKeys(): EnumLabelKeysModel<BoatTypeEnum>[] {
    return [
      { key: BoatTypeEnum.SEGELBOOT_SEGELYACHT, label: 'bvs.segelyachtSegelboot' },
      { key: BoatTypeEnum.MOTORBOOT_MOTORYACHT, label: 'bvs.motoryachtMotorboot' }
    ];
  }

  export function getEnumByKey(key: BoatTypeEnum): EnumLabelKeysModel<BoatTypeEnum> | undefined {
    return getLabelKeys().find(k => k.key === key);
  }
}
